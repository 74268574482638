<template>
  <div class="survey-summary p-3 py-5 items-center w-full justify-between flex-row bg-gray-200">

    <h3 class="md:w-2/5 mb-4">{{ $t('default.survey.summary') }}</h3>

    <div class="flex items-center flex-col w-full justify-between">
      <div class="question mb-2 flex flex-col md:flex-row w-full justify-between"
        v-for="(category, i) in category_grouping" :key="`${i}`">
        <label class="w-full md:w-2/5">
          {{ catTrans(category) }}
        </label>
        <div class="answer w-full md:w-3/5 justify-between">
          <BarChart :value="valueForCategory(category)" :percentageValue="normalizedValueForCategory(category)" />
        </div>
      </div>
    </div>
    <SurveyTopBottomSummary :questions="questions" :index="1" :display_summary="display_summary" :clang="clang" />
  </div>
</template>

<script>
import BarChart from '@/components/BarChart'
import SurveyTopBottomSummary from '@/components/SurveyTopBottomSummary'
import translator from "../mixins/translator";

export default {
  props: ['category_grouping', 'questions', 'display_summary', 'clang'],
  mixins: [translator],
  components: {
    SurveyTopBottomSummary,
    BarChart
  },
  methods: {
    valueForCategory(category) {
      const questionsArray = Object.values(category["questions"])
      if (questionsArray.length < 1) {
        return 0
      }
      const sum = questionsArray.reduce((col, question) => {
        return col + question["average"]
      }, 0)
      return sum / questionsArray.length
      // oldie way here. also true, but better to actually count it
      // return category['sum'] / category['total_answers']
    },
    normalizedValueForCategory(category) {
      const questionsArray = Object.values(category["questions"])
      if (questionsArray.length < 1) {
        return 0
      }
      const sum = questionsArray.reduce((col, question) => {
        return col + question["normalized_average"]
      }, 0)
      return sum / questionsArray.length
    },
    catTrans(category) {
      return category.translations.length === 0 ? category['name']
        : this.translator(this.clang, category)
    }
  }
}
</script>

<style lang="scss">
.survey-summary {
  label {
    font-size: 14px;
  }
}
</style>
