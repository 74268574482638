<template>
  <div class="bar-chart" :title="`${formattedNumber} / ${max}`">
    <div class="background">
      <div class="bar" :style="{ width: `${width}%` }" :data-value="value" :data-max="max"></div>
    </div>
    <label v-if="showValue">
      {{ formattedNumber }}
    </label>
  </div>
</template>

<script>

export default {
  name: 'BarChart',
  props: {
    // actual value (e.g. 30.2)
    value: {
      required: true,
      type: Number
    },
    // optional value to determine the width of the bar
    // in case of separately normalized values, from 0.0 to 1.0
    percentageValue: {
      required: false,
      type: Number,
      default: null
    },
    // optional text to place next to the chart
    showValue: {
      required: false,
      type: Boolean,
      default: true
    },
    max: {
      required: false,
      type: Number,
      default: 100
    }
  },
  computed: {
    formattedNumber() {
      if (this.value !== null) {
        if (this.value % 1 === 0) {
          return this.value.toFixed(0);
        }
        return this.value.toFixed(2)
      }
      return "-"
    },

    width() {
      // console.log("barchart render", this.value, "out of", this.max)
      if (this.percentageValue !== null) {
        return this.percentageValue * 100
      }
      return (this.value / this.max).toFixed(4) * 100
    }
  },
  methods: {

  }
}

</script>

<style lang="scss">
.bar-chart {
  display: flex;

  label {
    flex-basis: 60px;
    font-size: 14px; // about 0.9 rem.. but need to match the tailwind text-xs for now
    margin: 0 5px;
  }

  .background {
    flex: 1;
    position: relative;
    background: var(--gray-100);
    border-radius: 4px;
    overflow: hidden;
    margin: 1px 0;

    .bar {
      top: 0;
      left: 0;
      display: block;
      height: 100%;
      position: absolute;
      background: var(--halsa-red);
    }
  }

  &:nth-child(2) {
    .background {
      .bar {
        background: var(--halsa-blue);
      }
    }
  }
}
</style>