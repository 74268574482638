<template>
  <MainViewContainer>
    <div class="col-span-10 md:col-span-7">
      <h1>{{ $t('default.dashboard.greetings', { name: userName }) }}</h1>
      <div class="mb-40 grid grid-cols-12 gap-1 mt-10">
        <div class="col-span-12 md:col-span-10">
          <table class="shadow-lg bg-white table-auto w-full">
            <tr class="font-bold text-lg">
              <th class="bg-gray-100 text-left px-2 py-2 md:px-8 md:py-4">{{
        $t('default.dashboard.survey_table_name')
      }}
              </th>
              <th class="bg-gray-100 text-center">{{ $t('default.dashboard.members') }}</th>
              <th class="bg-gray-100 text-center">{{ $t('default.dashboard.topic2') }}</th>
              <th class="bg-gray-100"></th>
            </tr>
            <tr v-for="(org, i) in organizations" :key="i" :class="i % 2 ? 'bg-gray-100' : ''">
              <td class="px-2 py-2 md:px-8 md:py-4 text-green hover:text-green-dark cursor-pointer"
                @click="$router.push({ name: 'organization', params: { org: org.id } })">
                {{ org['name'] }}</td>
              <td class="py-4 text-center">{{ org['total_members'] }}</td>
              <td class="py-4 text-center">{{ org['groups'].length }}</td>
            </tr>
            <tr v-if="organizations.length === 0">
              <td class="px-2 py-2 md:px-8 md:py-4 text-center">
                No organizations found
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>

  </MainViewContainer>
</template>

<script>
import MainViewContainer from '@/components/MainViewContainer'
import { computed, reactive } from 'vue'
import { useStore } from 'vuex'

export default {
  components: {
    MainViewContainer,
  },
  setup() {
    const store = useStore()
    const state = reactive({
      user: computed(() => store.state.user.user)
    })

    return {
      state,
    }
  },

  mounted() {
    if (this.$store.state.organizations.organizations === null) {
      this.$store.dispatch('organizations/fetchOrganizations')
    }
  },

  computed: {
    organizations() {
      const all = this.$store.state.organizations.organizations || []
      all.sort((a, b) => a.name.localeCompare(b.name, "fi"))
      return all
    },
    userName() {
      return this.state.user ? this.state.user.first_name : ''
    },
  },
}
</script>
