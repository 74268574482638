<template>
  <div class="data-protection bg-gradient-to-r from-indigo-300 to-blue-200">
    <div class="min-h-screen flex justify-center">
      <div class="text-container bg-white shadow overflow-hidden sm:rounded-lg pb-8">
        <h1>
          {{ $t('default.data-protection.title') }}
        </h1>
        <div v-html="htmlContent"></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useI18n } from 'vue-i18n'
import CONTENT from "../locales/data-protection"

const { locale } = useI18n()

const htmlContent = computed(() => {
  return CONTENT[locale.value]
})
</script>

<style>
.data-protection {
  & .text-container {
    width: 90%;
    margin: 4rem 0;
    padding: 2rem 2rem 6rem;

    >h1,
    >div {
      max-width: 700px;
      margin: 0 auto;
    }

    & h1 {
      text-align: left;
      margin: 2rem auto;
    }

    & h2 {
      text-align: left;
      margin: 2rem 0;
    }

    & p {
      margin: 2rem 0;
    }

    & ul,
    & ol {
      list-style: normal;
      padding-left: 2rem;

      & li {
        margin: 0.8rem 0;
      }
    }
  }
}
</style>
