<template>
  <div>
    <select v-model="$i18n.locale" @change="languageClicked"
      class="border border-gray-300 rounded-full text-gray-600 h-10 pl-5 pr-10 bg-white hover:border-gray-400 focus:outline-none appearance-none">
      <option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang.shortName">
        {{ lang.name }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: 'locale-changer',
  data() {
    return {
      langs:
        [
          { name: 'Finnish', shortName: 'fi' },
          { name: 'English', shortName: 'en' },
          { name: 'Swedish', shortName: 'sv' },
        ]
    }
  },

  mounted() {
    this.setLanguage(this.$i18n.locale)
  },

  methods: {
    languageClicked(event) {
      this.setLanguage(event.target.value)
    },
    setLanguage(locale) {
      localStorage.setItem('defaultLanguage', locale)
      this.$store.commit('survey/setLang', locale)
      this.$store.commit('results/setLang', locale)
    }
  }
}
</script>
