<template>
  <div class="modal-container" @click.self="$emit('close')">
    <div class="modal">
      <div class="close-cross" @click="$emit('close')">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6 6L18 18M6 18L18 6L6 18Z" stroke="#3F3F46" stroke-width="2" stroke-linecap="round"
            stroke-linejoin="round" />
        </svg>
      </div>
      <div class="columns-12">
        <slot name="content"></slot>
        <div class="button-toolbar">
          <slot name="buttons"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
  A simple modal that allows for two slots. Usage:

  <SimpleModal @close="modalOpen = false">
    <template #content>
      <h2>content here </h2>
    </template>
    <template #buttons>
      <button class="green" @click="ok()">
        ok
      </button>
      <button class="red" @click="cancel()">
        cancel
      </button>
    </template>
  </SimpleModal>
 */
export default {
  name: "SimpleModal",
  props: {}
}
</script>

<style lang="scss">
.modal-container {
  background: rgba(200, 199, 199, 0.4);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.modal {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  padding: 40px 40px;
  background: #fff;
  width: 80%;
  max-width: 960px;
  border-radius: 0.75rem;

  @media screen and (max-width: 640px) {
    top: 3%;
    width: 90%;
    padding: 40px 20px;
  }

  & .close-cross {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }

  & img,
  & iframe {
    max-width: 100%;
    margin: 20px auto;
  }

  & h1,
  & h2 {
    text-align: center;
  }

  p {
    margin: 20px 0;
  }

  & .button-toolbar {
    display: flex;
    justify-content: center;
    margin: 40px 0;

    & button {
      min-width: 8rem;
      // FIXME: this oughta be in index.scss. Do it when clearning the other buttons
      // Also the font-size is wrong. Figma says 16px (convert to rem)
      line-height: 40px;
      padding-left: 4rem;
      padding-right: 4rem;
    }

    p {
      color: var(--text-grey);
      text-align: center;
      margin: 20px 0 0;
    }
  }
}
</style>