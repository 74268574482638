<template>
  <div class="w-full">
    <div class="flex px-3 py-5 items-center w-full justify-between">
      <div class="w-2/5">
        {{ index === '' ? '' : index + 1 }}{{ index === '' ? '' : '.&nbsp;' }}{{ translator(clang, question) }}
      </div>
      <div class="flex items-center w-full justify-between flex-col md:flex-row">
        <div class="question flex flex-col w-3/4 justify-center items-center">
          <span>NPS</span>
          <h1 class="text-4xl text-black-900 font-display font-bold">{{question['nps'].toFixed(1)}}</h1>
        </div>
        <table class="bg-white table-auto 1/4">
          <tr>
            <td class="text-right px-2">{{ question['promoters'] }}</td>
            <td class="text-left">{{ $t('default.user_results.promoters') }}</td>
          </tr>
          <tr>
            <td class="text-right px-2">{{ question['passives'] }}</td>
            <td class="text-left">{{ $t('default.user_results.passives') }}</td>
          </tr>
          <tr>
            <td class="text-right px-2">{{ question['detractors'] }}</td>
            <td class="text-left">{{ $t('default.user_results.detractors') }}</td>
          </tr>
        </table>
      </div>
    </div>
    <div class="flex">
      <div class="my-4 flex flex-col justify-end items-center brick-container"
           v-for="i in [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]" :key="i">
        <div class="brick flex justify-center items-center" :style="brickStyle(i)">
          {{ (((question['counts'][i] || 0) / total) * 100).toFixed(1) }}%
        </div>
        <span class="mt-2">{{i}}</span>
      </div>
    </div>
  </div>
</template>

<script>

import twconfig from '../../tailwind.config'
import translator from "../mixins/translator";

export default {
  name: 'ChoiceQuestionResult',
  props: ['question', 'index', 'clang'],
  mixins: [translator],
  methods: {
    brickStyle(x) {
      const colors = twconfig.theme.extend.colors
      const red = colors.red.DEFAULT
      const green = colors.green.DEFAULT
      let value = (this.question['counts'][x] || 0) / this.total
      let style = {
        height: `${((value / this.maxValue)) * 100}%`
      }
      if (x < 7)
        style['background'] = red
      else if (x < 9)
        style['border'] = `1px solid ${red}`
      else
        style['background'] = green
      return style
    }
  },
  computed: {
    total() {
      return Object.values(this.question['counts']).reduce((x, y) => x + y)
    },
    maxValue() {
      return Math.max(...Object.values(this.question['counts'])) / this.total
    }
  }
}

</script>

<style scoped>
.brick-container {
  flex-shrink: 0;
  flex-grow: 1;
  height: 200px;
}
.brick {
  width: 100%;
}
</style>
