<template>
  <div class="flex flex-col">
    <div>
      {{ index + 1 }}. {{ transQuestion }}
    </div>
    <div class="mt-4">
      <vue-slider class="bg-white border-2 border-white rounded w-full py-1 px-2 md:py-2 md:px-4 text-gray-700"
        ref="slider" v-model="scale_value" @change="this.$emit('update:netpromAns', $event)" v-bind="options" :min="0"
        :max="10" :interval="1" :marks="true" :disabled="disabled" />
    </div>
  </div>
</template>

<script>

import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'

export default {
  name: "NetPromoterScore",
  components: {
    VueSlider,
  },
  emits: ['update:netpromAns'],
  data() {
    return {
      scale_value: 0,
      options: {
        dotSize: 18,
        width: 'auto',
        height: 5,
        contained: false,
        direction: 'ltr',
        data: null,
        dataLabel: 'label',
        dataValue: 'value',
        disabled: false,
        clickable: true,
        duration: 0.5,
        adsorb: false,
        lazy: false,
        tooltip: 'none',
        tooltipPlacement: 'top',
        tooltipFormatter: void 0,
        useKeyboard: true,
        keydownHook: null,
        dragOnClick: false,
        enableCross: true,
        fixed: false,
        minRange: void 0,
        maxRange: void 0,
        order: true,
        dotOptions: void 0,
        dotAttrs: void 0,
        process: true,
        dotStyle: {
          backgroundColor: '#00D1B5'
        },
        railStyle: {
          backgroundColor: '#D1D5DB'
        },
        processStyle: {
          backgroundColor: '#D1D5DB'
        },
        tooltipStyle: void 0,
        stepStyle: void 0,
        stepActiveStyle: void 0,
        labelStyle: void 0,
        labelActiveStyle: void 0,
      }
    }
  },
  computed: {
    transQuestion() {
      const reducedArray = {}
      let translatedQuestion = {}
      this.question.question.translations.forEach(function (item) { reducedArray[`${item.locale}`] = item.text })
      if (reducedArray[this.current_lang] !== undefined) {
        translatedQuestion = reducedArray[this.current_lang]
      } else if (reducedArray['en'] !== undefined) {
        translatedQuestion = reducedArray['en']
      } else if (reducedArray['fi'] !== undefined) {
        translatedQuestion = reducedArray['fi']
      } else if (reducedArray['sv'] !== undefined) {
        translatedQuestion = reducedArray['sv']
      }
      return translatedQuestion
    }
  },
  props: {
    question: Object,
    current_lang: String,
    index: Number,
    disabled: {
      type: Boolean,
      default: false,
    },
  }
}
</script>

