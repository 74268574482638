<template>
  <div>{{ index + 1 }}. {{ transQuestion }}</div>
  <select
    class="bg-gray-300 appearance-none border-2 border-gray-200 rounded w-full py-1 px-2 md:py-2 md:px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-indigo-500 mt-4"
    :value="select_ans" :disabled="disabled" @change="$emit('update:select_ans', $event.target.value)">
    <option disabled value="">Please select one</option>
    <option v-for="op in options" :value="op.id" :key="op.id">
      {{ op.option.translations[0].text }}
    </option>
  </select>
</template>

<script>

export default {
  name: "SelectionOption",
  data() {
    return {
      options: this.question.question.options,
    };
  },
  props: {
    question: Object,
    select_ans: String,
    current_lang: String,
    index: Number,
    disabled: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    transQuestion() {
      const reducedArray = {}
      let translatedQuestion = {}
      this.question.question.translations.forEach(function (item) { reducedArray[`${item.locale}`] = item.text })
      if (reducedArray[this.current_lang] !== undefined) {
        translatedQuestion = reducedArray[this.current_lang]
      } else if (reducedArray['en'] !== undefined) {
        translatedQuestion = reducedArray['en']
      } else if (reducedArray['fi'] !== undefined) {
        translatedQuestion = reducedArray['fi']
      } else if (reducedArray['sv'] !== undefined) {
        translatedQuestion = reducedArray['sv']
      }
      return translatedQuestion
    }
  },
}
</script>

<style scoped>
.dropdown {
  background: #dddddd;
  border: white;
  border-radius: 5px;
}
</style>
