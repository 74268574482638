<template>
  <MainViewContainer :showBackIcon="true">
    <div class="col-span-10 md:col-span-7">
      <div class="grid grid-cols-1">
        <h1
          class="text-2xl md:text-4xl text-black-900 font-display font-bold xl:text-5xl text-left xl:text-bold font-heading">
          {{ groupName }} group
        </h1>
      </div>

      <div class="flex md:items-center justify-between mt-6">
        <div class="md:w-2/3">
          <h2 class="text-1xl md:text-2xl text-black-900 font-display xl:text-2xl text-left
            font-heading">{{ $t('default.members.members') }}</h2>
        </div>
        <div class="md:w-1/3">
          <router-link v-if="state.organization"
            :to="{ name: 'invite', params: { org: state.organization.id, group: group, whereToGo: 'members' } }">
            <button class="bg-green text-gray-100 py-1 px-2 md:py-2 md:px-4 w-full rounded-full tracking-wide
                                 font-semibold font-display focus:outline-none focus:shadow-outline hover:bg-green-dark
                                 shadow-lg flex justify-around items-center" type="button">
              {{ $t('default.members.invite') }}
            </button>
          </router-link>
        </div>
      </div>
      <div class="grid grid-cols-12 gap-1 mt-10">
        <div class="col-span-12 md:col-span-12">
          <table class="shadow-lg bg-white table-auto w-full">
            <tr :class="i % 2 ? 'bg-gray-100' : ''" v-for="(member, i) in members" :key="member['id']">
              <td class="px-8 py-4">
                {{ member['first_name'] ? member['first_name'] : member['email'] }}
                {{ member['last_name'] ? member['last_name'] : '' }}
              </td>
              <td class="px-8 py-4 font-bold text-green hover:text-green-dark cursor-pointer">
                <router-link :to="{ name: 'member-results', params: { usr: member['user_id'], org: orgId } }">
                  {{ $t('default.dashboard.view') }}
                </router-link>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="flex md:items-center justify-between mt-10 py-8" v-if="isAdminUser">
        <div class="md:w-2/3">
          <h2 class="text-1xl md:text-2xl text-black-900 font-display xl:text-2xl text-left
            font-heading">{{ $t('default.dashboard.invitations') }}</h2>
        </div>
        <div class="md:w-1/3" v-if="invitations && invitations.length">
          <button class="bg-green text-gray-100 py-1 px-2 md:py-2 md:px-4 w-full rounded-full tracking-wide
                                 font-semibold font-display focus:outline-none focus:shadow-outline hover:bg-green-dark
                                 shadow-lg flex justify-around items-center" type="button"
            @click="SendInvitationReminderToAll">
            {{ $t('default.dashboard.send_invitation_reminders') }}
          </button>
        </div>
      </div>
      <div class="grid grid-cols-12 gap-1 mt-10" v-if="isAdminUser">
        <div class="col-span-12 md:col-span-12">
          <table class="shadow-lg bg-white table-auto w-full">
            <tr class="bg-gray-100">
              <td class="px-8 py-4 font-semibold">{{ $t('default.dashboard.user_email') }}</td>
              <td class="px-8 py-4 font-semibold">{{ $t('default.dashboard.invitation_status') }}</td>
              <td class="px-8 py-4 font-semibold">{{ $t('default.dashboard.first_email_sent_at') }}</td>
              <td class="px-8 py-4 font-semibold">{{ $t('default.dashboard.reminder_sent_at') }}</td>
              <td class="px-8 py-4 font-semibold">{{ $t('default.dashboard.send_invitations') }}</td>
            </tr>
            <tr :class="i % 2 ? 'bg-gray-100' : ''" v-for="(invitation, i) in invitations" :key="invitation">
              <td class="px-8 py-4">
                {{ invitation.organization_user_email }}
              </td>
              <td class="px-8 py-4">
                {{ invitation.status }}
              </td>
              <td class="px-8 py-4">
                {{ formatedDatetime(invitation.created) }}
              </td>
              <td class="px-8 py-4">
                {{ formatedDatetime(invitation.second_reminder_sent_at ?
    invitation.second_reminder_sent_at :
    (invitation.first_reminder_sent_at ? invitation.first_reminder_sent_at : '-')) }}
              </td>
              <td class="px-8 py-4 justify-around grid items-center">
                <div class="justify-items-center">
                  <button class="px-8 py-4 font-bold text-green hover:text-green-dark cursor-pointer"
                    @click="SendInvitationReminder(invitation.id)">
                    {{ $t('default.dashboard.invite') }}
                  </button>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <SuccessModal :success-message="$t('default.invite.success')" :text="$t('default.dashboard.reminder_successful')"
      :closeModalText="$t('default.dashboard.okay')" @closeModal="closeModal = !closeModal" v-if="!closeModal">
    </SuccessModal>
  </MainViewContainer>
</template>

<script>
import MainViewContainer from '@/components/MainViewContainer'
import { computed, onMounted, reactive, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import axios from '@/axios-instance'
import SuccessModal from '@/components/SuccessModal'
import { stringToDatetime } from '@/datetimeUtils'
export default {
  name: 'Dashboard',
  components: {
    MainViewContainer,
    SuccessModal
  },
  setup() {
    const route = useRoute()
    const store = useStore()
    const group = route.params.group
    const orgId = route.params.org
    const members = ref([])
    const invitations = ref([])
    const state = reactive({
      organization: computed(() => store.state.organization.organization),
      defaultGroups: computed(() => store.state.organization.defaultGroups)
    })
    let groupName = ref(null)

    store.dispatch('organization/fetchGroups', orgId).then((d) => {
      groupName.value = d.find(x => x.id === parseInt(group)).name
    })

    const isAdminUser = computed(() => {
      return store.getters['user/isAdminUser']
    })

    const fetchMembers = () => {
      axios.get(`/api/v1/organizations/users/group_users/?group=${group}&org=${route.params.org}`)
        .then((res) => {
          members.value = res.data
        }).catch((e) => {
          console.log(e)
        })
    }
    const fetchInvitations = () => {
      axios.get(`/api/v1/organizations/users/invite?group=${group}`)
        .then((res) => {
          invitations.value = res.data
        })
    }
    const SendInvitationReminderToAll = () => {
      let data = {
        'group': group
      }
      axios.post('/api/v1/organizations/users/invite/remind/', data)
        .then((res) => {
          if (res.status == 200) {
            closeModal.value = false
          }
          fetchInvitations()
        })
    }
    const SendInvitationReminder = (id) => {
      let data = {
        'id': id
      }
      axios.post('/api/v1/organizations/users/invite/remind/', data)
        .then((res) => {
          if (res.status == 200) {
            closeModal.value = false
          }
          fetchInvitations()
        })
    }
    const formatedDatetime = (str) => {
      return stringToDatetime(str)
    }
    const closeModal = ref(true)
    onMounted(() => {
      fetchMembers()
      fetchInvitations()
    })
    return {
      state,
      group,
      members,
      orgId,
      invitations,
      SendInvitationReminderToAll,
      SendInvitationReminder,
      formatedDatetime,
      closeModal,
      isAdminUser,
      groupName
    }
  },
  mounted() {
    this.$store.dispatch('organization/fetchOrganization', this.$route.params.org)
  }
  ,
  computed: {
    orgName() {
      return this.state.organization ? this.state.organization['name'] : ''
    }
  }
}
</script>
