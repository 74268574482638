<template>
  <table class="shadow-lg bg-white table-auto w-full selfleader-table">
  <thead class="bg-gray-100">
    <th v-for="(header, i) in headings" :key="i" class="px-8 py-4 font-semibold">
      {{ header }}
    </th>
  </thead>
  <tbody>
    <tr v-for="(row, i) in rows" :key="'row-'+i">
      <td v-for="(cell, i) in row" :key="'cell-'+i">
        <template v-if="typeof(cell) == 'object'">
          <a v-if="cell.callback" @click="cell.callback(cell)">
            <span v-if="cell.html" v-html="cell.html"></span>
            <template v-else>
              {{ cell.text }}
            </template>
          </a>
          <template v-else>
            <span v-if="cell.html" v-html="cell.html"></span>
            <template v-else>
              {{ cell.text }}
            </template>
          </template>
        </template>
        <template v-else>
          {{ cell }}
        </template>
      </td>
    </tr>
  </tbody>
  </table>
</template>

<script>

export default {
  name: 'SimpleTable',
  props: {
    headings: {
      type: Array,
      required: true
    },
    rows: {
      type: Array, // array of arrays
      required: true
    }
  },
  components: {},
  setup() {}
}
</script>

<style lang="scss">
  .selfleader-table {
    margin-top: 20px;
    margin-bottom: 40px;

    & th {
      background: var(bg-gray-100);
    }

    & td:nth-child(2n) {
      background: var(bg-gray-100);
    }

    & td,
    & th {
      padding: 16px 32px;

      & svg {
        display: inline-block;
      }
    }

    & a {
      font-weight: bold;
    }
  }
</style>