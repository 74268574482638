<template>
  <header class="navigation">
    <nav class="flex justify-between">
      <div class="flex-none pr-2">
        <img class="inline h-8 ml-5 md:ml-10 mt-5 md:mt-12 cursor-pointer" src="../assets/halsa-logo.png" alt="Logo"
          @click="this.$router.push('/')">
        <NavigationHierarchy class="h-8 ml-5 md:ml-10 mt-4 md:mt-8"></NavigationHierarchy>
      </div>

      <div class="flex items-center md:mr-10">
        <LocaleChanger class="flex-1"></LocaleChanger>
        <router-link :to="{ name: 'userResults', params: { usr: user.id } }"
          class="flex-1 login-status text-gray-500 text-sm mr-3 ml-6" v-if="user && Object.keys(user).length > 0">
          {{ user.first_name || user.username }}
        </router-link>
        <img class="cursor-pointer ml-0" src="../assets/svg/logout.svg" alt="logout" @click="logout">
      </div>
    </nav>
  </header>
</template>

<script>
import LocaleChanger from '@/components/LocaleChanger'
import { mapActions, mapState } from 'vuex'
import NavigationHierarchy from "./NavigationHierarchy"

export default {
  name: 'Navigation',
  components: {
    LocaleChanger,
    NavigationHierarchy,
  },
  methods: {
    ...mapActions('user', ['logout'])
  },
  computed: {
    ...mapState('user', ['user'])
  }
}
</script>

<style scoped>
.navigation {
  max-width: 1600px;
  margin: 0 auto;
  width: 100%;
}

.login-status {
  /** Matching padding in the logo */
  /* padding-right: 2.5rem; */
}

@media screen and (max-width: 500px) {
  .navigation {
    padding-right: 0;
  }
}
</style>