<template>
  <SimpleModal @close="$emit('close')">
    <template #content>
      <h2>{{ userTask.task[`title_${$i18n.locale}`] }}</h2>
      <div v-html="userTask.task[`html_description_${$i18n.locale}`]"></div>
      <template v-if="userTask.completed_at === null">
        <p class="instructions">
          {{ $t("default.user_results.task_instructions") }}
        </p>
        <textarea rows="5" v-model="state.feedback" class="bg-gray-300 appearance-none border-2 border-gray-200 rounded w-full py-1 px-2 md:py-2 md:px-4 text-gray-700
                  leading-tight focus:outline-none focus:bg-white focus:border-indigo-500"></textarea>
      </template>
      <div v-else class="completed">
        <p v-html="$t('default.user_results.you_completed_task', { completedAt: completedAt })"></p>
        <p><i>{{ userTask.note }}</i></p>
      </div>

    </template>
    <template #buttons>
      <button v-if="userTask.completed_at === null" class="green" @click="complete()">
        {{ $t("default.user_results.mark_task_complete") }}
      </button>
      <button v-else class="red" @click="undo()">
        {{ $t("default.user_results.mark_task_incomplete") }}
      </button>
    </template>
  </SimpleModal>
</template>

<script>
import { reactive } from 'vue'
import SimpleModal from '@/components/SimpleModal'
import { parse, format } from 'date-fns'

export default {
  name: "TaskModal",
  components: {
    SimpleModal,
  },
  props: {
    userTask: Object
  },
  setup() {
    const state = reactive({ feedback: '' })
    return {
      state
    }
  },
  methods: {
    complete() {
      this.$store.dispatch('tasksGoals/completeUserTask', { userTask: this.userTask, feedback: this.state.feedback })
      this.$emit('close')
    },
    undo() {
      this.$store.dispatch('tasksGoals/undoUserTask', { userTask: this.userTask, feedback: this.state.feedback })
      this.$emit('close')
    }
  },
  computed: {
    completedAt() {
      if (this.userTask.completed_at === null) {
        return ""
      }
      const d = parse(this.userTask.completed_at, "yyyy-MM-dd", new Date())
      return format(d, "dd.MM.yyyy")
    }
  }
}
</script>

<style scoped>
.instructions {
  margin: 40px 0 20px;
}

.completed {
  margin: 40px 0;
}
</style>
