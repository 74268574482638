import axios from '@/axios-instance'

export const ModuleTasksGoals = {
  namespaced: true,

  state: {
    userGoals: [],
    userTasks: []
  },

  getters: {
  },

  mutations: {
    setUserTasks(state, data) {
      state.userTasks = data
    },
    setUserGoals(state, data) {
      state.userGoals = data
    }
  },

  actions: {
    fetchUserTasks(context, { usr: userId }) {
      return axios.get(`/api/v1/tasksgoals/usertasks/?user_id=${userId}`)
        .then((res) => {
          context.commit('setUserTasks', res.data)
        })
        .catch((err) => {
          console.log(err)
        })
    },

    fetchUserGoals(context, { usr: userId }) {
      return axios.get(`/api/v1/tasksgoals/usergoals/?user_id=${userId}`)
        .then((res) => {
          context.commit('setUserGoals', res.data)
        })
        .catch((err) => {
          console.log(err)
        })
    },

    completeUserTask(context, { userTask, feedback }) {
      manageTask(context, userTask, "COMPLETE", feedback)
    },

    undoUserTask(context, { userTask, feedback }) {
      manageTask(context, userTask, "UNDO", feedback)
    },

    completeUserGoal(context, { userGoal, feedback }) {
      manageGoal(context, userGoal, "COMPLETE", feedback)
    },

    undoUserGoal(context, { userGoal, feedback }) {
      manageGoal(context, userGoal, "UNDO", feedback)
    },
  }
}

const manageTask = (context, userTask, action, note) => {
  return axios.put(`/api/v1/tasksgoals/usertasks/${userTask.id}`, { action, note })
    .then((res) => {
      context.dispatch("fetchUserTasks", { usr: res.data.user_id })
    })
    .catch((err) => {
      console.log(err)
    })
}

const manageGoal = (context, userGoal, action, note) => {
  return axios.put(`/api/v1/tasksgoals/usergoals/${userGoal.id}`, { action, note })
    .then((res) => {
      context.dispatch("fetchUserGoals", { usr: res.data.user_id })
    })
    .catch((err) => {
      console.log(err)
    })
}