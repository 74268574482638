<template>
  <MainViewContainer :showBackIcon="true">
    <div class="col-span-10 md:col-span-7">
      <h1>
        {{ username }}:
        {{ surveyname }}
      </h1>
      <div class="flex md:items-center mt-6">
        <div class="md:w-1/3">
          <h2>
            {{ $t("default.user_results.answers") }}
          </h2>
        </div>
        <div class="md:w-1/3 w-1/12"></div>
      </div>
      <div class="grid grid-cols-3">
        <div class="flex">
          <Loader v-if="loading"></Loader>
        </div>
      </div>
      <div class="grid grid-cols-12 gap-1 mt-10 mb-6">
        <div class="col-span-12 md:col-span-12" v-if="results['survey_questions']">
          <SurveySummary :category_grouping="results['category_grouping']" :display_summary="results['display_summary']"
            :questions="surveyScaleQuestions" :clang="state.clan" title="Survey Summary" />

          <CategoryResults v-for="(category, i, loopIndex) in results['category_grouping']"
            :key="`category-${category['id']}`" :questions="surveyScaleQuestionsForCategory(category)"
            :category="category" :index="loopIndex" :user-result="true">
          </CategoryResults>

          <hr />

          <div class="mt-5"></div>

          <template v-for="(question, i) in surveyQuestionsAtTheEnd" :key="question['id']">
            <template v-if="question['question']['answers'].length">
              <SingleValueUserResult v-if="['SO', 'SC'].indexOf(question['question']['type']) > -1"
                :question="question['question']" :index="i">
              </SingleValueUserResult>

              <OpenQuestionResult v-else-if="['FT', 'ST'].indexOf(question['question']['type']) > -1
    " :question="question['question']" :index="i" :single-answer="true">
              </OpenQuestionResult>

              <NPSQuestionResult v-else-if="question['question']['type'] === 'NPS'" :question="question['question']"
                :index="i">
              </NPSQuestionResult>
            </template>
            <div v-else>No answers</div>
          </template>
        </div>
      </div>
    </div>
  </MainViewContainer>
</template>

<script>
import MainViewContainer from "@/components/MainViewContainer";
import Loader from "../components/Loader";
import { useStore } from "vuex";
import { computed, onMounted, reactive, ref } from "vue";
import { useRoute } from "vue-router";
import SurveySummary from "@/components/SurveySummary";
import SingleValueUserResult from "@/components/SingleValueUserResult";
import NPSQuestionResult from "@/components/NPSQuestionResult";
import OpenQuestionResult from "@/components/OpenQuestionResult";
import CategoryResults from "@/components/CategoryResults";

export default {
  name: "UserSurveyAnswers",
  components: {
    MainViewContainer,
    SurveySummary,
    CategoryResults,
    SingleValueUserResult,
    OpenQuestionResult,
    NPSQuestionResult,
    Loader,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const state = reactive({
      user: computed(() => store.state.user.user),
      clan: computed(() => store.state.survey.currentLanguage),
    });
    const loading = ref(true);
    const surveyId = route.params.survey;
    const userId = route.params.usr;

    onMounted(() => {
      store.dispatch("results/fetchUserSurveyAnswers", { userId, surveyId });
    });

    return {
      state,
      loading,
      surveyId,
      userId,
    };
  },
  computed: {
    username() {
      const u = this.results["user"];
      return u && ([u.first_name, u.last_name].join(" ").trim() || u.username);
    },
    surveyname() {
      return this.results["name"];
    },
    surveyScaleQuestions() {
      return this.results['survey_questions']
        .filter((q) => q['question']['type'] === 'SC')
        .map((q) => q['question'])
    },
    id() {
      return this.$route.params.id;
    },
    categoryIds() {
      return Object.keys(this.results["category_grouping"]);
    },
    results() {
      return this.$store.state.results.userSurveyData[this.surveyId] !==
        undefined
        ? this.$store.state.results.userSurveyData[this.surveyId][this.userId]
        : {};
    },
    surveyQuestionsAtTheEnd() {
      return this.results['survey_questions']
        .filter(
          (x) =>
            !x['question']['category'] ||
            this.categoryIds.indexOf(x['question']['category']['id']) > -1 ||
            x['question']['type'] !== 'SC'
        )
    }
  },

  methods: {
    surveyScaleQuestionsForCategory(category) {
      return this.results['survey_questions'].filter(
        (x) =>
          x['question']['answers'].length &&
          x['question']['type'] === 'SC' &&
          x['question']['category'] &&
          x['question']['category']['id'] === category['id']
      )
    },
  },

  watch: {
    results: {
      handler: function (res) {
        if (res !== undefined && res !== null) {
          this.loading = false;
        }
      },
    },
  },
};
</script>

<style scoped></style>
