import axios from "axios";
import router from "@/router";
import {
  getAccessToken,
  getRefreshToken,
  setAccessToken,
  flushTokens,
} from "@/store/modules/moduleUser";

const instance = axios.create({
  // baseURL: process.env.BASE_URL || 'https://selfleader.montelcare.dev',
  timeout: 10000,
});

instance.defaults.headers.common = {
  "X-Requested-With": "XMLHttpRequest",
  "Content-Type": "application/json",
  "X-CSRFToken": document
    .querySelector('[name="csrfmiddlewaretoken"]')
    .getAttribute("value"),
};

instance.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${getAccessToken()}`;
  return config;
});

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    const code = parseInt(error.response && error.response.status);
    // Patch for now, proper fix will be having 2 axios instances.
    if (error.config.url.indexOf("auth/token") > -1) throw error;
    if (code === 401) {
      if (error.config && !error.config.__isRetryRequest) {
        console.log("token expired!");
        const refreshToken = getRefreshToken();
        return instance
          .post("/auth/token/refresh/", {
            refresh: refreshToken,
          })
          .then((response) => {
            setAccessToken(response.data.access);
          })
          .then(() => {
            error.config.__isRetryRequest = true;
            error.config.headers[
              "Authorization"
            ] = `Bearer ${getAccessToken()}`;
            instance.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${getAccessToken()}`;
            return instance(error.config);
          })
          .catch(() => {
            flushTokens();
            router.redirect("/login");
          });
      }
    }
    return Promise.reject(error);
  }
);

export default instance;
