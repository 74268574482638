export function sortQuestions(questionsArray) {
    return questionsArray.sort((a, b) => a['average'] <= b['average'] ? 1 : -1)
}

export function discardEmptyAnswers(questionsArray) {
    return questionsArray
        .filter((question) => {
            if (question.answers === undefined || question.answers.length === 0) {
                return false
            }
            return true;
        })
}


export function getTopQuestions(sortedQuestionsArray, amountToReturn) {
    return sortedQuestionsArray.slice(0, amountToReturn)

}

export function getBottomQuestions(sortedQuestionsArray, amountToReturn) {
    return sortedQuestionsArray
        .slice(sortedQuestionsArray.length - amountToReturn, sortedQuestionsArray.length)
        .reverse()
}

export function formatDecimal(number) {
    if (typeof number != "number") {
        return "-"
    }
    if (number % 1 === 0) {
        return number.toFixed(0);
    }
    return number.toFixed(2)
}