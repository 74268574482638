<template>
  <div>
    <div v-if="sortedAnsweredQuestions.length" class="p-3 py-5 items-center w-full justify-between flex-row"
      :class="{ 'bg-gray-200': index % 2 }">
      <div class="w-3/5"></div>
      <div class="flex flex-wrap items-baseline flex-row w-full justify-between w-full">
        <div class="w-full md:w-1/2 pr-1">
          <div class="w-full flex justify-center mb-2 text-sm">
            {{ $t('default.survey.strongest') }}
          </div>
          <div class="question mb-2 flex flex-row w-full justify-between items-center"
            v-for="(question, i) in topQuestions" :key="`cq-${i}`">
            <span
              class="flex w-2/5 text-gray-700 text-xs md:text-sm text-left md:text-right h-auto md:h-28 items-center">
              {{ translator(clang, question) }}
            </span>
            <div class="answer flex justify-between w-3/5">
              <div class="text-xs md:text-sm option px-4" :class="wideWidth">
                {{ formatDecimal(question['average']) }}
              </div>
              <span
                class="text-xs leading-none text-center text-white h-6 w-11 flex justify-center items-center bg-gray"
                :style="{ width: `${100 - question['average']}%` }">
              </span>
              <span
                class="text-xs md:text-xs leading-none text-center text-white h-6 w-11 flex justify-center items-center"
                :class="[`${i % 2 ? 'bg-indigo' : 'bg-red'}`]" :style="{ width: `${question['average']}%` }">
              </span>
            </div>
          </div>
        </div>
        <div class="w-full md:w-1/2 pl-1">
          <div class="w-full flex justify-center mt-6 md:mt-0 mb-2 text-sm">{{ $t('default.survey.weakest') }}</div>
          <div class="question mb-2 flex md:flex-row-reverse w-full justify-between items-center"
            v-for="(question, i) in bottomQuestions" :key="`cq-${i}`">
            <span class="flex w-2/5 text-xs md:text-sm text-gray-700 h-auto md:h-28 items-center">
              {{ translator(clang, question) }}
            </span>
            <div class="answer w-3/5 flex justify-between flex-row-reverse md:flex-row">
              <div class="text-xs leading-none text-center text-white h-6 flex justify-center items-center"
                :class="[`${i % 2 ? 'bg-indigo' : 'bg-red'}`]" :style="{ width: `${question['average']}%` }">
              </div>
              <span
                class="text-xs leading-none text-center text-white h-6 w-11 flex justify-center items-center bg-gray"
                :style="{ width: `${100 - question['average']}%` }">
              </span>
              <div class="text-xs md:text-sm option px-4" :class="wideWidth">
                {{ formatDecimal(question['average']) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import translator from "../mixins/translator"
import {
  sortQuestions, discardEmptyAnswers,
  formatDecimal, getBottomQuestions, getTopQuestions
} from "../survey-helpers.js"

export default {
  props: {
    index: {
      required: true
    },
    display_summary: {
      required: true
    },
    questions: {
      default: []
    },
    userResult: {
      default: false
    },
    clang: {
      default: 'fi'
    },
  },
  mixins: [translator],
  computed: {
    displaySummary() {
      let s = this.display_summary
      if (this.sortedAnsweredQuestions.length / 2 < s)
        s = Math.ceil(this.sortedAnsweredQuestions.length / 2)
      return s
    },
    sortedAnsweredQuestions() { return sortQuestions(discardEmptyAnswers(this.questions)) },
    topQuestions() {
      return getTopQuestions(this.sortedAnsweredQuestions, this.displaySummary)
    },
    bottomQuestions() {
      return getBottomQuestions(this.sortedAnsweredQuestions, this.displaySummary)
    },
    wideWidth() {
      const avg = this.topQuestions.map(ele => ele['average'])
      for (let ele of avg) {
        if (ele % 1 === 0) {
          return 'w-20'
        }
      }
      return 'w-24'
    },
  },

  methods: {
    formatDecimal: formatDecimal
  }
}

</script>

<style scoped></style>
