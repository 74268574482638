<template>
  {{ index + 1 }}. {{ transQuestion }}
  <input
      class="h-8 bg-gray-300 appearance-none border-2 border-gray-200 rounded w-full py-1 px-2 md:py-2 md:px-4 text-gray-700
                      leading-tight focus:outline-none focus:bg-white focus:border-indigo-500"
      :value="shorttext_ans"
      :disabled="disabled"
      @input="$emit('update:shorttext_ans', $event.target.value)"
  />
</template>

<script>
export default {
  name: "ShortText",
  props: {
    question: Object,
    shorttext_ans: String,
    current_lang: String,
    index: Number,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    transQuestion() {
      const reducedArray = {}
      let translatedQuestion = {}
      this.question.question.translations.forEach(function (item) { reducedArray[`${item.locale}`] = item.text})
      if (reducedArray[this.current_lang] !== undefined) {
        translatedQuestion = reducedArray[this.current_lang]
      } else if (reducedArray['en']  !== undefined ) {
        translatedQuestion = reducedArray['en']
      } else if (reducedArray['fi']  !== undefined ) {
        translatedQuestion = reducedArray['fi']
      } else if (reducedArray['sv']  !== undefined ) {
        translatedQuestion = reducedArray['sv']
      }
      return translatedQuestion
    }
  },
}
</script>

<style scoped>

</style>
