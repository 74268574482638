<template>
  <AuthContainer>
    <form class="w-full max-w-sm" onsubmit="event.preventDefault()">
      <div class="md:flex md:items-center mb-6">
        <div class="md:w-1/3">
          <label class="block text-gray-500 font-bold text-left mb-1 md:mb-0 pr-4" for="inline-email">
            {{ $t("default.login.email") }}
          </label>
        </div>
        <div class="md:w-2/3">
          <input class="
                    bg-gray-200
                    appearance-none
                    border-2 border-gray-200
                    rounded
                    w-full
                    py-1
                    px-2
                    md:py-2 md:px-4
                    text-gray-700
                    leading-tight
                    focus:outline-none focus:bg-white focus:border-indigo-500
                  " id="inline-email" type="email" placeholder="" v-model="email" @keyup.enter="loginUser" />
        </div>
      </div>
      <div class="md:flex md:items-center">
        <div class="md:w-1/3">
          <label class="block text-gray-500 font-bold text-left mb-1 md:mb-0 pr-4" for="inline-password">
            {{ $t("default.login.password") }}
          </label>
        </div>
        <div class="md:w-2/3">
          <input class="
                    bg-gray-300
                    appearance-none
                    border-2 border-gray-200
                    rounded
                    w-full
                    py-1
                    px-2
                    md:py-2 md:px-4
                    text-gray-700
                    leading-tight
                    focus:outline-none focus:bg-white focus:border-indigo-500
                  " id="inline-password" type="password" placeholder="" v-model="password" @keyup.enter="loginUser" />
        </div>
      </div>
      <div class="md:flex md:items-center mb-5 mt-2">
        <div class="md:w-1/3"></div>
        <div class="md:w-2/3">
          <div class="text-xs font-display font-semibold text-gray-400 text-left">
            {{ $t("default.login.forget_password") }}
            <router-link class="cursor-pointer !text-gray-300 underline"
              to="/login/reset-password/">{{ $t("default.login.new_password") }}</router-link>
          </div>
        </div>
      </div>
      <div class="md:flex md:items-center mb-5 mt-2" v-if="getLoginErrorMessage || errors">
        <div class="md:w-1/3"></div>
        <div class="md:w-2/3">
          <div class="text-xs font-display font-semibold text-red text-left">
            {{ errors }}{{ getLoginErrorMessage }}
          </div>
        </div>
      </div>

      <div class="md:flex md:items-center">
        <div class="md:w-1/3"></div>
        <div class="md:w-2/3">
          <button class="green" @click="loginUser()">
            <img src="../assets/svg/tail-spin.svg" alt="" class="h-6" v-if="apiRequestPending" />
            {{ $t("default.login.login_text") }}
          </button>
        </div>
      </div>

      <div style="visibility: hidden" class="md:flex mt-4 md:items-center">
        <div class="md:w-1/5"></div>
        <div class="md:w-4/5">
          <button class="
                    bg-red
                    text-gray-100
                    py-1
                    px-2
                    md:py-2 md:px-4
                    w-full
                    rounded-full
                    tracking-wide
                    font-semibold font-display
                    focus:outline-none focus:shadow-outline
                    hover:bg-red-dark
                    shadow-lg
                  " type="button">
            {{ $t("default.login.office_login") }}
          </button>
        </div>
      </div>
    </form>
  </AuthContainer>
</template>

<script>
import AuthContainer from "../components/AuthContainer";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Login",
  data() {
    return {
      email: "",
      password: "",
      apiRequestPending: false,
      errors: null,
    };
  },
  components: {
    AuthContainer,
  },
  created() {
    this.errors = null;
  },
  methods: {
    ...mapActions("user", ["signin"]),
    validateEmail(email) {
      return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        email
      );
    },
    loginUser() {
      if (!this.validateEmail(this.email)) {
        this.errors = this.$t("default.login.error1");
        return;
      }
      if (!this.password) {
        this.errors = this.$t("default.login.error2");
        return;
      }
      this.errors = null;
      this.apiRequestPending = true;
      this.signin({
        username: this.email.toLowerCase(),
        password: this.password,
      }).then((user) => {
        this.apiRequestPending = false;
        if (!this.$route.query.redirect) {
          if (['org_admin', 'coach', 'admin'].includes(user.type['type'])) {
            this.$router.push({ name: 'dashboard' })
          }
          else if (user.type['type'] === 'org_user') {
            this.$router.push({ name: 'userResults', params: { usr: user.id } })
          }
        } else {
          this.$router.push(this.$route.query.redirect)
        }
      });
    },
  },
  computed: {
    ...mapGetters("user", ["getLoginErrorMessage"]),
  },
};
</script>
