<template>
  <div class="dropdown relative text-left" @click="showDropDown = !showDropDown">
    <div>
      <button type="button" :class="enableStyling" id="menu-button" aria-expanded="true" aria-haspopup="true">
        {{ selectedOption.name }}
        <!-- Heroicon name: solid/chevron-down -->
        <svg v-if="isEnable" class="-mr-1 ml-2 h-5 w-5 absolute" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
          fill="currentColor" aria-hidden="true" style="top: 10; right: 8">
          <path fill-rule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clip-rule="evenodd" />
        </svg>
      </button>
    </div>

    <div v-if="showDropDown && isEnable"
      class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none w-full z-50"
      role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
      <div class="py-1" role="none">
        <a v-for="option in options" :key="option.id" @click="$emit('selectOption', option)"
          :class="{ 'bg-gray-300': option.id === selectedOption.id }" href="#"
          class="text-gray-700 block px-4 py-2 text-sm focus:bg-gray-300 hover:bg-gray-300" role="menuitem" tabindex="-1"
          id="menu-item-0">{{ option.name }}</a>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "DropDown",
  data() {
    return {
      showDropDown: false
    }
  },
  props: ['options', 'selectedOption', 'isEnable'],
  emits: ['selectOption'],
  computed: {
    enableStyling() {
      let sty = 'text-left appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-indigo-500'
      return this.isEnable === true ? 'bg-gray-300 ' + sty
        : 'bg-gray ' + sty
    }
  }
}
</script>

<style lang="scss">
.dropdown {
  button {
    min-height: 40px;
  }
}
</style>
