<template>
  <MainViewContainer :showBackIcon="true">
    <div class="col-span-12 pb-10">

      <div class="flex md:items-center justify-between mt-6">
        <h2 class="md:w-2/3 mb-0">
          {{ $t('default.members.members') }}
        </h2>
        <div class="md:w-1/3 flex justify-end">
          <router-link v-if="state.organization"
            :to="{ name: 'invite', params: { org: state.organization.id, whereToGo: 'allMembers' } }">
            <button class="green" type="button">
              {{ $t('default.members.invite') }}
            </button>
          </router-link>
        </div>
      </div>
      <div class="grid grid-cols-12 gap-1 mt-10">
        <div class="col-span-12 md:col-span-10">
          <table class="shadow-lg bg-white table-auto w-full">
            <tr :class="i % 2 ? 'bg-gray-100' : ''" v-for="(member, i) in state.members" :key="member['id']">
              <td class="px-8 py-4">
                {{ member['first_name'] ? member['first_name'] : member['email'] }}
                {{ member['last_name'] ? member['last_name'] : '' }}
              </td>
              <td class="px-8 py-4 font-bold text-green hover:text-green-dark cursor-pointer">
                <router-link :to="{ name: 'member-results', params: { usr: member['user_id'] }, org: orgId }">
                  {{ $t('default.dashboard.view') }}
                </router-link>
              </td>
            </tr>
          </table>
        </div>
      </div>

      <div class="flex align-center md:items-center justify-between mt-10 py-8" v-if="isAdminUser">
        <h2 class="md:w-2/3 mb-0">{{ $t('default.dashboard.invitations') }}</h2>
        <div class="md:w-1/3 flex justify-end" v-if="invitations && invitations.length">
          <button class="green" type="button" @click="SendInvitationReminderToAll">
            {{ $t('default.dashboard.send_invitation_reminders') }}
          </button>
        </div>
      </div>

      <div class="grid grid-cols-12 gap-1 mt-10" v-if="isAdminUser">
        <div class="col-span-12 md:col-span-12 overflow-auto">
          <table class="shadow-lg bg-white table-auto w-full text-sm">
            <tr class="bg-gray-100 font-semibold">
              <td class="px-4 py-4">{{ $t('default.dashboard.user_email') }}</td>
              <td class="px-4 py-4">{{ $t('default.dashboard.invitation_status') }}</td>
              <td class="px-4 py-4">{{ $t('default.dashboard.scheduled') }}</td>
              <td class="px-4 py-4">{{ $t('default.dashboard.first_email_sent_at') }}</td>
              <td class="px-4 py-4">{{ $t('default.dashboard.reminder_sent_at') }}</td>
              <td class="px-4 py-4">{{ $t('default.dashboard.organization_group_name') }}</td>
              <td class="px-4 py-4">{{ $t('default.dashboard.send_invitations') }}</td>
            </tr>
            <tr :class="i % 2 ? 'bg-gray-100' : ''" v-for="(invitation, i) in invitations" :key="invitation">
              <td class="px-8 py-4">
                {{ invitation.organization_user_email }}
              </td>
              <td class="px-8 py-4">
                {{ invitation.status }}
              </td>
              <td class="px-8 py-4">
                {{ formatedDateTime(invitation.scheduled_for) }}
              </td>
              <td class="px-8 py-4">
                {{ !(invitation.scheduled_for && invitation.status === 'Pending')
    ? formatedDateTime(invitation.created) : '-' }}
              </td>
              <td class="px-8 py-4">
                {{ formatedDateTime(invitation.second_reminder_sent_at ?
    invitation.second_reminder_sent_at :
    (invitation.first_reminder_sent_at ? invitation.first_reminder_sent_at : '-')) }}
                <img src="../assets/svg/tail-spin-color.svg" alt="" class="h-6" v-if="invitationsSentinel" />
              </td>
              <td class="px-8 py-4">
                {{ invitation.group_name }}
              </td>
              <td class="px-8 py-4 justify-around grid items-center">
                <div class="justify-items-center">
                  <button v-if="!(invitation.scheduled_for && invitation.status === 'Pending')"
                    class="px-8 py-4 font-bold text-green hover:text-green-dark cursor-pointer blocked"
                    @click="SendInvitationReminder(invitation.id)">
                    {{ $t('default.dashboard.invite') }}
                  </button>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <SuccessModal :success-message="$t('default.invite.success')" :text="$t('default.dashboard.reminder_successful')"
      :closeModalText="$t('default.dashboard.okay')" @closeModal="closeModal = !closeModal" v-if="!closeModal">
    </SuccessModal>
  </MainViewContainer>
</template>

<script>
import MainViewContainer from '@/components/MainViewContainer'
import { computed, reactive, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import axios from '@/axios-instance'
import SuccessModal from '@/components/SuccessModal'
import { stringToDatetime } from '@/datetimeUtils'

export default {
  name: 'AllMembers',
  components: {
    MainViewContainer,
    SuccessModal
  },
  setup() {
    const route = useRoute()
    const store = useStore()
    const orgId = route.params.org
    const members = ref([])
    const invitations = ref([])
    const invitationsSentinel = ref(false)
    const state = reactive({
      organization: computed(() => useStore().state.organization.organization),
      members: computed(() => useStore().state.organization.members)
    })
    const isAdminUser = () => {
      return store.getters['user/isAdminUser']
    }
    const fetchInvitations = () => {
      axios.get(`/api/v1/organizations/users/invite?org=${route.params.org}`)
        .then((res) => {
          invitations.value = res.data
          invitationsSentinel.value = false
        })
    }
    const formatedDateTime = (str) => {
      return stringToDatetime(str)
    }
    const SendInvitationReminderToAll = () => {
      let data = {
        'org_id': orgId
      }
      axios.post('/api/v1/organizations/users/invite/remind/', data)
        .then((res) => {
          if (res.status == 200) {
            closeModal.value = false
            invitationsSentinel.value = true
          }
          setTimeout(() => fetchInvitations(), 10000)
        })
    }
    const closeModal = ref(true)
    const SendInvitationReminder = (id) => {
      let data = {
        'id': id
      }
      axios.post('/api/v1/organizations/users/invite/remind/', data)
        .then((res) => {
          if (res.status == 200) {
            closeModal.value = false
            invitationsSentinel.value = true
          }
          setTimeout(() => fetchInvitations(), 10000)
        })
    }
    return {
      state,
      organization_id: orgId,
      members,
      orgId,
      invitations,
      fetchInvitations,
      formatedDateTime,
      isAdminUser,
      SendInvitationReminder,
      SendInvitationReminderToAll,
      closeModal,
      invitationsSentinel,
    }
  },
  mounted() {
    this.$store.dispatch('organization/fetchOrganization', this.$route.params.org).then(() => {
      this.$store.dispatch('organization/fetchMembers', this.$route.params.org)
    }).then(() => this.fetchInvitations())
  },
  computed: {
    orgName() {
      return this.state.organization ? this.state.organization['name'] : ''
    }
  }
}
</script>
