<template>
   <div class="auth-container mx-auto justify-center items-center flex">
      <div>
         <h1>
            {{ $t('default.dashboard.hello') }}
         </h1>
         <div class="lg:flex font-serif">
            <div class="lg:w-1/2 xl:max-w-screen-sm">
               <div class="mt-10 pl-1 pr-6 border-r-2">
                  <div class="mt-12 flex justify-center">
                     <slot></slot>
                  </div>
               </div>
            </div>
            <div class="lg:flex items-center justify-center flex-1 pl-6" v-if="message !== ''">
               <div class="max-w-xs transform duration-200 text-sm cursor-pointer" v-html="message">
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
console.log("message");
export default {
   name: 'AuthContainer',
   props: {
      message: {
         type: String,
         default: ""
      }
   }
}
</script>
<style>
.auth-container {
   height: 60vh;

   ol {
      list-style: normal;
      padding-left: 1rem;

      li {
         margin: 5px 0;
      }
   }

   p {
      margin: 10px 0;
   }
}
</style>
