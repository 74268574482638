<template>
  <div>{{ index + 1 }}. {{ transQuestion }}</div>
  <div class="mt-4">
    <textarea rows="5" :value="freetex_ans" :disabled="disabled" @input="$emit('update:freetex_ans', $event.target.value)"
      class="bg-gray-300 appearance-none border-2 border-gray-200 rounded w-full py-1 px-2 md:py-2 md:px-4 text-gray-700
                         leading-tight focus:outline-none focus:bg-white focus:border-indigo-500"></textarea>
    <a v-if="freetex_ans">{{ freetex_ans.length }}/1000</a>
  </div>
</template>

<script>
export default {
  name: "FreeText",
  props: {
    question: Object,
    freetex_ans: String,
    current_lang: String,
    index: Number,
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      text_area_text: '',
      message: 'mensas',
    };
  },
  computed: {
    transQuestion() {
      const reducedArray = {}
      let translatedQuestion = {}
      this.question.question.translations.forEach(function (item) { reducedArray[`${item.locale}`] = item.text })
      if (reducedArray[this.current_lang] !== undefined) {
        translatedQuestion = reducedArray[this.current_lang]
      } else if (reducedArray['en'] !== undefined) {
        translatedQuestion = reducedArray['en']
      } else if (reducedArray['fi'] !== undefined) {
        translatedQuestion = reducedArray['fi']
      } else if (reducedArray['sv'] !== undefined) {
        translatedQuestion = reducedArray['sv']
      }
      return translatedQuestion
    }
  },
}
</script>

