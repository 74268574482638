<template v-if="alertData">
    <div class="p-6 text-xs font-display font-semibold rounded alert-container" :class="isError ? ' text-red text-left bg-red-50' :
    'text-green text-left bg-green-50'">
        <div class="flex items-center pb-2">
            <span v-if="isError">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 
                    1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414
                    10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd" />
                </svg>
            </span>
            <span v-else>
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9
                    10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                </svg>
            </span>
            <span class="ml-4 font-bold" :class="isError ? 'text-red-900' : 'text-green-900'">{{ alertHeading }}</span>
        </div>
        <div>
            <ul v-for="alert in alertData" :key="alert" class="list-disc ml-12 mb-1">
                <li>{{ alert }}</li>
            </ul>
        </div>
        <span>
            <svg v-if="closable" viewBox="0 0 24 24" class="h-5 w-5 close-btn" fill="currentColor"
                xmlns="http://www.w3.org/2000/svg" @click="onClose">
                <path d="M6 6L18 18M6 18L18 6L6 18Z" stroke="#3F3F46" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" />
            </svg>
        </span>
    </div>
</template>

<script>
export default {
  name: "Alerts",
  props: ['alertHeading', 'alertData', 'isError', 'onClose', 'closable']
}
</script>

<style scoped>
.alert-container {
    position: relative;
}

.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}
</style>