<template>
  <div class="scale flex flex-wrap ">
    <div class="question-text w-full md:w-2/6 md:pr-4 self-center">
      {{ index + 1 }}. {{ transQuestion }}
    </div>
    <div class="w-full w-full md:w-4/6" @mousedown="mouseTouchDownCallback" @mouseup="mouseTouchUpCallback"
      @touchstart="mouseTouchDownCallback" @touchend="mouseTouchUpCallback">

      <transition name="slide-fade" mode="out-in">
        <div v-if="showChosenUp" class="being-chosen-option">
          {{ chosenOption }}
        </div>
        <div v-else class="being-chosen-option text-white"> &nbsp; </div>
      </transition>

      <vue-slider ref="slider" v-model="scale_value" :min="min_value" @change="this.$emit('update:sliderAns', $event)"
        v-bind="dynamicOptions" :disabled="disabled" />

      <div>
        <ul class="flex">
          <div class="flex-1 text-xs text-marksGray" v-for="(mark, index) in marks" :key="index">
            <div v-if="index === 0" class="h-7 text-left">
              {{ mark }}
            </div>
            <div v-if="index === stepMaxIndex" class="h-7 text-right">
              {{ mark }}
            </div>
          </div>
        </ul>
      </div>

      <transition name="slide-fade" mode="out-in">
        <p v-if="showChosenDown" class="chosen-option">{{ chosenOption }}</p>
        <div v-else id="last" class="chosen-option"> &nbsp; </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { ref, unref } from "vue";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/antd.css";

export default {
  name: "Scale",
  components: {
    VueSlider,
  },
  props: {
    question: Object,
    current_lang: {
      type: String,
      default: "fi",
    },
    disabled: {
      type: Boolean,
      default: false
    },
    index: Number,
  },
  emits: ["update:sliderAns"],

  setup(props) {
    let { question } = unref(props);
    let scale_value = ref();

    const showMarks = ref(true)
    const showChosenUp = ref(false)
    const showChosenDown = ref(false)
    const beenTouch = ref(false)

    const mouseTouchDownCallback = () => {
      showChosenUp.value = true
      showMarks.value = false
      showChosenDown.value = false
      beenTouch.value = true
    }

    const mouseTouchUpCallback = () => {
      showChosenUp.value = false
      showChosenDown.value = true
    }

    // Pick the default value for the slider. Either middle step value
    // or the average of assumed max value of the whole slider
    if (question.question.steps.steps.length % 2 !== 0) {
      const middleStep =
        question.question.steps.steps[
        Math.floor(question.question.steps.steps.length / 2)
        ];
      if (!middleStep.value) {
        console.warn(`No last step found for ${this.question}`);
        scale_value.value = 0;
      } else {
        scale_value.value = middleStep.value;
      }
    } else {
      const lastStep =
        question.question.steps.steps[question.question.steps.steps.length - 1];
      if (!lastStep.value) {
        console.warn(`No last step found for ${this.question}`);
        scale_value.value = 0;
      } else {
        scale_value.value = lastStep.value / 2;
      }
    }

    return {
      scale_value,
      showMarks,
      showChosenUp,
      showChosenDown,
      beenTouch,
      mouseTouchDownCallback,
      mouseTouchUpCallback
    };
  },

  computed: {
    steps() {
      return this.question.question.steps.steps;
    },
    min_value() {
      return this.steps[0].value;
    },
    step() {
      return this.steps[1].value;
    },
    stepMaxIndex() {
      return this.question.question.steps.steps.length - 1;
    },
    max_value() {
      return this.steps[this.steps.length - 1].value;
    },
    marks() {
      const reducedArray = { en: [], fi: [], sv: [] };
      this.question.question.steps.steps.forEach(function (item) {
        item.translations.forEach(function (subitem) {
          reducedArray[`${subitem.locale}`].push(subitem.text);
        });
      });
      return reducedArray[this.current_lang].length !== 0
        ? reducedArray[this.current_lang]
        : reducedArray["fi"];
    },
    transQuestion() {
      const reducedArray = {};
      let translatedQuestion = {};
      this.question.question.translations.forEach(function (item) {
        reducedArray[`${item.locale}`] = item.text;
      });
      if (reducedArray[this.current_lang] !== undefined) {
        translatedQuestion = reducedArray[this.current_lang];
      } else if (reducedArray["en"] !== undefined) {
        translatedQuestion = reducedArray["en"];
      } else if (reducedArray["fi"] !== undefined) {
        translatedQuestion = reducedArray["fi"];
      } else if (reducedArray["sv"] !== undefined) {
        translatedQuestion = reducedArray["sv"];
      }
      return translatedQuestion;
    },
    intervalArray() {
      const interval = (this.max_value - this.min_value) / this.steps.length;
      return Array(this.steps.length)
        .fill()
        .map((_, i) => [
          this.min_value + i * interval,
          this.min_value + (i + 1) * interval,
        ]);
    },
    chosenIntervalIndex() {
      // Step through the steps of the question and find the option with the shortest distance
      // to the current value. This assumes the options are in ascending value-order. Mimics the
      // logic in the backend step_at().
      let currentDistance = 99999;
      let previousStepIndex = null;
      for (let stepIndex = 0; stepIndex < this.steps.length; stepIndex++) {
        const stepValue = this.steps[stepIndex].value
        const myDistance = Math.abs(stepValue - this.scale_value)
        // we overstepped, it has to be the previous one
        if (myDistance > currentDistance) {
          return previousStepIndex
        }
        currentDistance = myDistance
        previousStepIndex = stepIndex
      }
      // didn't find one in the loop, it has to be the last one
      return previousStepIndex
    },
    chosenOption() {
      return this.marks[this.chosenIntervalIndex];
    },
    middleValue() {
      return this.max_value / 2;
    },
    dynamicOptions() {
      const options = {
        dotSize: 20,
        width: "auto",
        height: 10,
        contained: true,
        direction: "ltr",
        data: null,
        dataLabel: "label",
        dataValue: "value",
        disabled: false,
        clickable: true,
        duration: 0.5,
        adsorb: false,
        lazy: false,
        tooltip: "none",
        tooltipPlacement: "top",
        tooltipFormatter: void 0,
        useKeyboard: false,
        keydownHook: null,
        dragOnClick: false,
        enableCross: true,
        fixed: false,
        minRange: void 0,
        maxRange: void 0,
        min: this.min_value,
        max: this.max_value,
        order: true,
        process: true,
        processStyle: {
          backgroundColor: this.beenTouch ? "#BDDDBD" : "#BDBDBD",
        },
        railStyle: {
          backgroundColor: this.beenTouch ? "#BDDDBD" : "#BDBDBD",
        },
        dotOptions: {
          style: {
            backgroundColor: this.beenTouch ? "#00D1B5" : "#D1D5DB",
            border: this.beenTouch ? "1px solid #D1D5DB" : "1px solid #D1D5DB",
            boxShadow: "0.5px 0.5px 2px 1px rgba(107,107,107,.36)",
          },
        },
        tooltipStyle: void 0,
        stepStyle: void 0,
        stepActiveStyle: void 0,
        labelStyle: void 0,
        labelActiveStyle: void 0,
      }
      return options;
    },
  },
};
</script>

<style scope>
.scale {}

.slide-fade-enter-active {
  transition: all 0.5s ease;
}

.slide-fade-leave-active {
  transition: all 0.5s cubic-bezier(-1, -0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}

.being-chosen-option,
.chosen-option {
  color: #565656;
  font-size: 0.9rem;
  height: 2rem;
  margin-top: -10px;
  text-align: center;
}

.being-chosen-option {
  color: #444;
}
</style>
