<template>
  <div class="flex p-3 py-5 items-center w-full justify-between">
    <div class="flex flex-col md:flex-row items-center w-full justify-between">
      <div class="w-full mb-2 md:mb-0 md:w-2/5 text-xs md:text-base">
        <template v-if="showQuestion">
          {{ index === '' ? '' : index + 1 }}{{ index === '' ? '' : '.&nbsp;' }}{{ translator(clang, question) }}
        </template>
      </div>
      <div v-if="role" class="flex flex-column w-1/5 text-xs">{{ role }}</div>
      <div class="flex items-center flex-col w-full md:w-3/5 justify-between">
        <div class="question flex flex-row w-full justify-between" v-for="(answer, i) in Object.keys(getCounts)"
          :key="`${answer}-${question['id']}`">
          <div class="answer w-4/5">

            CONTINUE FROM HERE: ADD THE BARCHART TO THIS THIS AND ALSO GET SOME PROPER FIXTURE FROM PROD

            <div class="text-xs leading-none text-center text-white h-6
                                             flex justify-center items-center"
              :class="[`${i % 2 ? 'bg-indigo' : 'bg-red'}`]"
              :style="{ width: `${(getCounts[answer] / question['answers'].length) * 100}%` }">
              {{ ((getCounts[answer] / question['answers'].length) * 100).toFixed(1) }}%
            </div>
          </div>
          <div class="option">{{ answer }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import translator from "../mixins/translator";

export default {
  name: 'ChoiceQuestionResult',
  props: {
    'question': {
      required: true
    },
    'index': {
      required: true
    },
    'clang': {
      required: true
    },
    'average': { //  For 360 survey
      default: false
    },
    'showQuestion': { //  For 360 survey
      default: true
    },
    'answers': { //  For 360 survey
      required: false
    },
    'role': { //  For 360 survey
      required: false
    },
    'counts': { //  For 360 survey
      required: false
    },
  },
  mixins: [translator],
  computed: {
    getCounts() {
      return this.counts || this.question['counts']
    },
  }
}

</script>

<style>
.question {
  margin-bottom: 1px;
}
</style>