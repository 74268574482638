<template>
  <Navigation></Navigation>
  <div class="main-container">
    <router-view></router-view>
  </div>
  <footer>
    <div class="footer-content">
      <ul class="links">
        <li>
          <span>»</span>
          <router-link :to="{ name: 'data-protection' }">
            {{ $t('default.data-protection.title') }}
          </router-link>
        </li>
        <li>
          <span>»</span>
          <a href="https://halsa.fi/">Halsa.fi</a>
        </li>
      </ul>
      <img src="./assets/halsa-a-logo.png" alt="Hälsa">
    </div>
  </footer>
</template>

<script>
import Navigation from './components/Navigation.vue'
export default {
  name: 'App',
  components: {
    Navigation
  }
}
</script>

<style>
.main-container {
  max-width: 1600px;
  margin: 0 auto 40px;
  margin-bottom: 40px;
  /* Making sure the footer is at the bottom on large screens */
  /* full - footer - header - margins */
  min-height: calc(100vh - 120px - 160px - 65px);
}

footer {
  @media print {
    display: none;
  }

  border-top: 1px solid #e9e9e9;
  box-shadow: inset 0 4px 12px #e1e1e1;
  background: linear-gradient(#f1f1f1, #e1e1e1);

  & .footer-content {
    max-width: 1600px;
    margin: 0 auto;
    padding: 1.5rem 2.5rem 2rem;
    font-size: 0.9rem;
    display: flex;
    justify-content: end;

    & .links {
      margin: 0.5rem 2rem;
      list-style: none;
    }

    >img {
      width: 60px;
      height: 60px;
    }

    & span {
      display: inline-block;
      margin-right: 0.5rem;
      color: var(--text-grey);
    }

    & a {
      color: var(--text-black);
    }
  }
}
</style>
