import axios from "@/axios-instance";

/**
 * Merge the translations to the surveys so that the current language attributes are available
 * on the top level.
 */

export const ModuleSurvey360 = {
  namespaced: true,

  state: {
    all: [],
    roles: [],
  },

  getters: {
    getSurveys: (state) => state.all,
    getRoles: (state) => state.roles
  },

  mutations: {
    setSurveys(state, data) {
      state.all = data
    },
    setRoles(state, data){
      state.roles = data
    }
  },

  actions: {
    fetchForOrganization({ commit }, org_id) {
      return axios
        .get(`/api/v1/surveys/360/?org_id=${org_id}`)
        .then((res) => {
          const data = res.data;
          commit("setSurveys", data);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    fetchRoles({ commit }, surveyId) {
      return axios
        .get(`/api/v1/surveys/360/${surveyId}/roles/`)
        .then((res) => {
          const data = res.data;
          commit("setRoles", data);
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
