<template>
  <AuthContainer :message="$t('default.signup.message')">
    <form class="w-full max-w-sm" onsubmit="event.preventDefault()">
      <div class="md:flex md:items-center mb-6">
        <div class="md:w-1/3">
          <label class="block text-gray-500 font-bold text-left mb-1 md:mb-0 pr-4" for="inline-fname">
            {{ $t('default.login.first_name') }}
          </label>
        </div>
        <div class="md:w-2/3">
          <input
            class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-1 px-2 md:py-2 md:px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-indigo-500"
            id="inline-fname" type="text" v-model="firstName" placeholder="">
        </div>
      </div>
      <div class="md:flex md:items-center mb-6">
        <div class="md:w-1/3">
          <label class="block text-gray-500 font-bold text-left mb-1 md:mb-0 pr-4" for="inline-lname">
            {{ $t('default.login.last_name') }}
          </label>
        </div>
        <div class="md:w-2/3">
          <input
            class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-1 px-2 md:py-2 md:px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-indigo-500"
            id="inline-lname" type="text" v-model="lastName" placeholder="">
        </div>
      </div>
      <div class="md:flex md:items-center mb-5">
        <div class="md:w-1/3">
          <label class="block text-gray-500 font-bold text-left mb-1 md:mb-0 pr-4" for="inline-password">
            {{ $t('default.login.password') }}
          </label>
        </div>
        <div class="md:w-2/3">
          <input
            class="bg-gray-300 appearance-none border-2 border-gray-200 rounded w-full py-1 px-2 md:py-2 md:px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-indigo-500"
            id="inline-password" type="password" placeholder="" v-model="password">
        </div>
      </div>
      <div class="md:flex md:items-center mb-5 mt-2" v-if="getSignupErrorMessage || errors">
        <div class="md:w-1/3"></div>
        <div class="md:w-2/3">
          <div class="text-xs font-display font-semibold text-red text-left">
            {{ errors }}{{ getSignupErrorMessage }}
          </div>
        </div>
      </div>
      <div class="md:flex md:items-center">
        <div class="md:w-1/3"></div>
        <div class="md:w-2/3">
          <button class="bg-green text-gray-100 py-1 px-2 md:py-2 md:px-4 w-full rounded-full tracking-wide
                             font-semibold font-display focus:outline-none focus:shadow-outline hover:bg-green-dark
                             shadow-lg flex justify-around items-center" type="button" @click="signupUser()">
            <img src="../assets/svg/tail-spin.svg" alt="" class="h-6" v-if="apiRequestPending">
            {{ $t('default.login.login_text') }}
          </button>
        </div>
      </div>
    </form>
  </AuthContainer>
</template>

<script>
import { useRoute } from 'vue-router'
import AuthContainer from '../components/AuthContainer'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'SignUp',
  data() {
    return {
      firstName: '',
      lastName: '',
      password: '',
      apiRequestPending: false,
      errors: null
    }
  },
  setup() {
    const route = useRoute()
    const token = route.params.token
    return {
      token
    }
  },
  components: {
    AuthContainer
  },
  methods: {
    ...mapActions('user', ['signup']),
    signupUser() {
      if (!this.password || !this.firstName || !this.lastName) {
        this.errors = this.$t('default.login.error2')
        return
      }
      this.errors = null
      this.apiRequestPending = true
      this.signup(
        { first_name: this.firstName, last_name: this.lastName, password: this.password, token: this.token })
        .then((user) => {
          this.apiRequestPending = false
          this.$router.push({ name: 'userResults', params: { usr: user.id } })
        })
    }
  },
  computed: {
    ...mapGetters('user', ['getSignupErrorMessage'])
  }
}
</script>
