<template>
  <div class="survey-top-bottom-summary-360">
    <div class="p-3 py-5 items-center w-full justify-between flex-row" :class="{ 'bg-gray-200': index % 2 }">
      <div class="w-3/5"></div>
      <div class="flex flex-wrap items-baseline flex-row w-full justify-between w-full">
        <div class="w-full md:w-1/2 pr-1">
          <div class="w-full flex justify-center mb-2 text-sm">
            {{ $t('default.survey.strongest') }}
          </div>
          <div class="question mb-2 flex flex-row w-full justify-between items-center"
            v-for="(question, i) in topQuestions" :key="`cq-${i}`">
            <label>
              {{ translator(clang, question) }}
            </label>
            <div class="roles">
              <div v-for="[group, value] in Object.entries(question.average)" :key="group" class="role-container">
                <label>
                  {{ group }}
                </label>
                <div class="chart">
                  <BarChart :value="value" :percentage-value="question['normalized_average'][group]" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="w-full md:w-1/2 pl-1">
          <div class="w-full flex justify-center mt-6 md:mt-0 mb-2 text-sm">{{ $t('default.survey.weakest') }}</div>
          <div class="question mb-2 flex md:flex-row-reverse w-full justify-between items-center"
            v-for="(question, i) in bottomQuestions" :key="`cq-${i}`">
            <label>
              {{ translator(clang, question) }}
            </label>
            <div class="roles">
              <div v-for="[group, value] in Object.entries(question.average)" :key="group" class="role-container">
                <label>
                  {{ group }}
                </label>
                <div class="chart">
                  <BarChart :value="value" :percentage-value="question['normalized_average'][group]" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BarChart from '@/components/BarChart'
import translator from "../mixins/translator";

export default {
  props: {
    index: {
      required: true
    },
    display_summary: {
      required: true
    },
    questions: {
      default: []
    },
    userResult: {
      default: false
    },
    clang: {
      default: 'fi'
    },
  },
  mixins: [translator],
  components: {
    BarChart
  },
  computed: {
    sortedQuestions() {
      let q = this.questions
      const sorted = q.sort((a, b) => a["normalized_average"]["all"] <= b["normalized_average"]["all"] ? 1 : -1);
      return sorted
    },
    displaySummary() {
      // hardcoding 360 display summary here
      let s = 3
      //let s = this.display_summary
      if (this.sortedQuestions.length / 2 < s)
        s = Math.ceil(this.sortedQuestions.length / 2)
      return s
    },
    topQuestions() {
      console.log("sidp", this.displaySummary)
      return this.sortedQuestions.slice(0, this.displaySummary)
    },
    bottomQuestions() {
      console.log('Number of bottom Questions: ', this.sortedQuestions.slice(this.sortedQuestions.length - this.displaySummary, this.sortedQuestions.length).reverse().length)
      return this.sortedQuestions.slice(
        this.sortedQuestions.length - this.displaySummary, this.sortedQuestions.length).reverse()
    },
    wideWidth() {
      const avg = this.topQuestions.map(ele => ele['average'])
      for (let ele of avg) {
        if (ele % 1 === 0) {
          return 'w-20'
        }
      }
      return 'w-24'
    },
  },
  methods: {
    ifDecimal(number) {
      if (typeof number != "number") {
        return "-"
      }
      if (number % 1 === 0) {
        return number.toFixed(0);
      }
      return number.toFixed(2)
    },
    sumAverages(question) {
      return Object.values(question["average"]).reduce((col, { normalized_average }) => col + normalized_average, 0)
    }
  }
}

</script>

<style>
.survey-top-bottom-summary-360 {
  .question {
    >label {
      flex: 1;
    }

    >div {
      flex: 2;
    }
  }

  .role-container {
    display: flex;
    flex-direction: row;
    margin: 0;

    label {
      font-size: 14px;
      flex-basis: 60px;
      overflow: hidden;
      margin: 0 5px;
    }

    .chart {
      flex: 1;
    }

    &:nth-child(2) {
      .chart {
        .background {
          .bar {
            background: var(--halsa-blue);
          }
        }
      }
    }
  }
}
</style>
