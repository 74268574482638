<template>
  <MainViewContainer :showBackIcon="true" :whereToGo="whereToGo">
    <div class="col-span-10 md:col-span-5">
      <h1 class="text-2xl text-black-900 font-display font-bold md:text-4xl text-left
      xl:text-bold font-heading">{{ $t('default.invite.invite_text') }}</h1>
      <div class="mt-4 md:mt-12">
        <p class="text-gray-600">{{ $t('default.invite.invite_text2') }}</p>
        <div class="flex items-start mt-12">
          <div class="md:w-1/5 text-gray-600">
            <p>{{ $t('default.invite.emails') }}</p>
            <p class="text-gray-400 text-sm w-20 mt-1">{{ $t('default.invite.emails_sub_text') }}</p>
          </div>
          <div class="md:w-4/5">
            <textarea rows="12" v-model="emails"
              class="bg-gray-300 appearance-none border-2 border-gray-200 rounded w-full py-1 px-2 md:py-2 md:px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-indigo-500"></textarea>
          </div>
        </div>
        <div class="flex items-start mt-6">
          <div class="md:w-1/5 text-gray-600">
            <p>{{ $t('default.invite.invite_in_group') }}</p>
          </div>
          <div class="md:w-4/5 group-selection">

            <label>
              <input type="radio" v-model="groupType" value="EXISTING_GROUP" name="newOrExistingGroup" />
              {{ $t('default.invite.existing_group') }}
            </label>

            <div class="sub-selection">
              <select v-model="selectedGroupId" :disabled="groupType !== 'EXISTING_GROUP'">
                <option v-for="group in groups" :key="group.id" :value="group.id">{{ group.name }}</option>
              </select>
            </div>

            <label>
              <input type="radio" v-model="groupType" value="NEW_GROUP" name="newOrExistingGroup" />
              {{ $t('default.invite.new_group') }}
            </label>

            <div class="sub-selection">
              <input type="text" v-model="groupName" :disabled="groupType !== 'NEW_GROUP'" />
            </div>

            <p class="text-gray-400 text-sm mt-1 flex align-center mb-1" v-if="groupType === 'NEW_GROUP'">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-green mr-1" fill="none" viewBox="0 0 24 24"
                stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              {{ $t('default.invite.new_group_will_be_created', { newGroup: groupName }) }}
            </p>
            <p class="text-gray-400 text-sm mt-1 mb-1" v-else>{{ $t('default.invite.group_select_placeholder') }}</p>
          </div>
        </div>

        <div class="flex items-start mt-6 mb-6 group-selection">
          <div class="md:w-1/5 text-gray-600">
            <p>{{ $t('default.invite.starting_survey') }}</p>
          </div>
          <div class="md:w-4/5">
            <select v-model="selectedSurveyId">
              <option v-for="survey in surveys" :key="survey.id" :value="survey.id">{{ survey.name }}</option>
            </select>
            <p class="text-gray-400 text-sm w-full mt-1">{{ $t('default.invite.survey_sub_text') }}</p>
          </div>
        </div>

        <div class="flex items-start mt-6">
          <div class="md:w-1/5 text-gray-600">
            <p>{{ $t('default.invite.email_greeting') }}</p>
            <p class="text-gray-400 text-sm w-20 mt-1">{{ $t('default.invite.email_greeting_help') }}</p>
          </div>
          <div class="md:w-4/5">
            <textarea rows="8" v-model="greetingText"
              class="bg-gray-300 appearance-none border-2 border-gray-200 rounded w-full py-1 px-2 md:py-2 md:px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-indigo-500"></textarea>
          </div>
        </div>
        <div v-if="errors.length" class="mb-2 mt-4">
          <Alerts :alertData="errors" :isError="true"
            :alertHeading="`${$t('default.invite.following')}  ${errors.length} ${$t('default.invite.errors')}`">
          </Alerts>
        </div>
        <div v-if="success">
          <Alerts :isError="false" :alertHeading="success"></Alerts>
        </div>
        <Alerts v-if="selectedDate" :alertData="[`${$t('default.invite.schedule_update')} ${nativeSelectedDate}`]"
          :alertHeading="`${$t('default.invite.schedule_update_heading')}`" :closable="true" :onClose="onClose"
          :isError="false" />
        <div class="flex items-end mt-6 align-center justify-end">
          <div class="flex justify-end">
            <button class="green mb-3 big send-invites-btn" type="button" @click.prevent="sendInvites()">
              {{ $t('default.invite.invitation_button') }}
            </button>
            <span
              class="watch-icon group cursor-pointer relative inline-block border-b border-gray-400 w-28 text-center">
              <Datepicker v-model="selectedDate" ref="datepicker" :minDate="minDate" format="dd.MM.yyyy H:mm"
                minutes-increment="5" :start-time="{ hours: 0, minutes: 0 }" />
              <span class="opacity-0 group-hover:opacity-100 pointer-events-none">
                {{ $t('default.invite.schedule_tooltip') }}
                <svg class="absolute text-black h-2 w-full left-0 top-full" x="0px" y="0px" viewBox="0 0 255 255"
                  xml:space="preserve">
                  <polygon class="fill-current" points="0,0 127.5,127.5 255,0" />
                </svg>
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <SuccessModal :success-message="$t('default.invite.success')" :text="selectedDate ? `${$t('default.invite.schedule_success_message')} ${nativeSelectedDate}`
    : $t('default.invite.emails_success')" success-button-link="dashboard"
      :success-button-text="$t('default.send_survey.success_button_text')" v-if="!errors.length && success.length">
    </SuccessModal>
  </MainViewContainer>
</template>

<script>
import { ref } from 'vue'
import axios from '@/axios-instance'
import MainViewContainer from '@/components/MainViewContainer'
import { computed, reactive } from 'vue'
import { useStore } from 'vuex'
import SuccessModal from '@/components/SuccessModal'
import Alerts from '@/components/Alerts'
import { format, parse, add, addMinutes } from 'date-fns'
import Datepicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'

export default {
  name: 'Invite',
  components: {
    MainViewContainer,
    SuccessModal,
    Alerts,
    Datepicker
  },
  props: {
    whereToGo: {
      default: 'dashboard',
    }
  },
  data() {
    return {
      errors: [],
      selectedDate: null,
      emails: '',
      greetingText: '',
      minDate: add(new Date(), { days: 1 }),
      success: '',
      defaultSelectedSurvey: {},
      selectedSurveyId: null,
      incorrectEmails: [],
    }
  },
  setup() {
    const store = useStore()

    const state = reactive({
      organization: computed(() => store.state.organization.organization),
      groups: computed(() => store.state.organization.groups),
      defaultGroups: computed(() => store.state.organization.defaultGroups),
    })
    const addGroup = (data) => store.commit('organization/addGroup', data)

    const selectedSurveyId = ref(null)
    const selectedGroupId = ref(
      state.groups && state.groups.length ? state.groups[0].id : null
    )

    const groupName = ref('')
    const groupType = ref('NEW_GROUP')

    return {
      state,
      selectedGroupId,
      groupName,
      groupType,
    }
  },
  mounted() {
    this.$store.dispatch('organization/fetchGroups', this.$route.params.org)
    this.$store.dispatch('organization/fetchOrganization', this.$route.params.org)
    this.$store.dispatch('survey/fetchAll')

    this.defaultSelectedSurvey = {
      name: this.$t('default.invite.no_survey_selected'),
      id: '-'
    }
    this.selectedSurveyId = this.defaultSelectedSurvey.id
  },
  computed: {
    orgName() {
      return this.state.organization ? this.state.organization['name'] : ''
    },
    surveys() {
      const all = [this.defaultSelectedSurvey, ...this.$store.state.survey.all]
      return all
    },
    groups() {
      return this.state.groups
    },

    nativeSelectedDate() {
      if (this.selectedDate)
        return format(this.selectedDate, "dd.MM.yyyy H:mm")
      return null
    },

    utcSelectedDate() {
      if (this.selectedDate)
        return format(addMinutes(this.selectedDate, this.selectedDate.getTimezoneOffset()), 'yyyy-MM-dd HH:mm:ss')
      return ''
    }

  },
  methods: {
    sendInvites() {
      this.errors = []
      this.success = ''
      if (!this.emails || !this.emails.trim()) {
        this.errors.push(this.$t('default.invite.emails_error1'))
        return
      }
      let emailsArray = this.emails.split('\n').filter(el => (el !== ''))
      if (this.groupType === "NEW_GROUP" && !this.groupName.trim().length) {
        this.errors.push(this.$t('default.invite.selected_group_missing'))
        return
      } else if (this.groupType === "EXISTING_GROUP" && !this.selectedGroupId === null) {
        this.errors.push(this.$t('default.invite.selected_group_missing'))
        return
      }
      const groupName = this.groupType === "NEW_GROUP" ?
        this.groupName :
        this.groups.filter((g) => g.id === this.selectedGroupId)[0].name
      const data = {
        'users': emailsArray
      }
      data['selected_group'] = groupName
      data['organization_id'] = this.$route.params.org
      if (this.selectedSurveyId !== this.defaultSelectedSurvey.id) {
        data['starting_survey'] = this.selectedSurveyId
      }
      if (this.greetingText.trim() !== '') {
        data['greeting_text'] = this.greetingText
      }
      if (this.utcSelectedDate !== '') {
        data['scheduled_for'] = this.utcSelectedDate
      }

      axios.post('/api/v1/organizations/users/invite', data)
        .then((res) => {
          this.emails = ''
          this.greetingText = ''
          this.errors = []
          if (res.data.invitations_sent) {
            this.success = `${this.$t('default.invite.email_send_successful')} ${res.data.invitations_sent.length} ${this.$t('default.invite.emails').toLowerCase()}`
          }
          if (res.data.invalid_emails && res.data.invalid_emails.length) {
            this.errors.push(`${this.$t("default.invite.email_sending_failed")} ${res.data.invalid_emails.join(', ')} ${this.$t("default.invite.please_check_addresses")}`)
          }
          if (res.data.already_invitations_sent && res.data.already_invitations_sent.length) {
            this.errors.push(`${this.$t("default.invite.invitation_already_sent")} ${res.data.already_invitations_sent.join(', ')}`)
          }
          if (res.data.already_members && res.data.already_members.length) {
            this.errors.push(`${this.$t("default.invite.already_members")} ${res.data.already_members.join(', ')}`)
          }
          if (res.data.invalid_emails) this.emails = res.data.invalid_emails.join('\n')
        })
    },

    onClose() {
      this.selectedDate = null
    }
  },

  watch: {
    groups: {
      handler: function (groups) {
        if (this.selectedGroupId === null) {
          this.selectedGroupId = groups.length ? groups[0].id : null
        }
      }
    }
  },

}

</script>

<style scoped type="scss">
.group-selection {
  color: rgba(75, 85, 99, 1);
}

.send-invites-btn {
  padding: 0.85rem 0.8rem 0.85rem 2rem;
  border-radius: 3rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  height: 48px;
}

.watch-icon {
  display: flex;
  background: #00d1b5;
  width: 49px;
  height: 48px;
  align-items: center;
  border-top-right-radius: 3rem;
  border-bottom-right-radius: 3rem;
  position: relative;
  border-left: 1px dashed #fff;
}

.watch-icon img {
  width: 25px;
  height: 25px;
  position: absolute;
  left: 10px;
  cursor: pointer;
}

.watch-icon .pointer-events-none {
  background: black;
  color: white;
  font-size: 12px;
  text-align: center;
  border-radius: 6px;
  width: 160px;
  padding: 5px 10px;
  z-index: 99;
  position: absolute;
  right: -55px;
  top: -25px;
}

.watch-icon input {
  padding: 0 !important;
  opacity: 0;
  width: 42px;
  cursor: pointer;
}

input::-webkit-calendar-picker-indicator {
  cursor: pointer;
  font-size: 40px;
  pointer-events: auto;
}


.datetime {
  background: #f6f6f6;
  padding: 20px 20px 20px 20px;
  font-size: 17px;
  color: #222;
  font-weight: 500;
  border: 1px solid #00d1b5;
  margin-top: 1rem;
}

.datetime span {
  font-weight: bold;
  font-size: 16px;
  color: #00d1b5;
  font-weight: 500;
  padding-left: 15px;
}

.group-selection input[type=text],
.group-selection select,
.group-selection option {
  padding: 0.5rem 1rem;
  line-height: 1.25;
  border: 2px solid rgba(229, 231, 235, 1.0);
  min-width: 15rem;
}

.group-selection input:disabled,
.group-selection select:disabled {
  background: #eee;
}

.group-selection label {
  display: block;
  margin-bottom: 10px;
  margin-top: 20px;
}

.group-selection label:first-child {
  margin-top: 0px;
}

.group-selection .sub-selection {
  padding-left: 20px;
}

/deep/ .vue-select {
  width: 100%;
}

/deep/ .vue-nonedropdown-item.highlighted,
.vue-dropdown-item.selected {
  background: lightgrey;
}

/deep/ .vue-input {
  height: 36px;
}

/deep/ input {
  padding-left: 10px;
}

/deep/ .dp__input_icon {
  color: white;
}

/deep/ .dp__input,
/deep/ .dp__clear_icon {
  display: none;
}
</style>
