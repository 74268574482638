<template>
  <div class="scale-question-result flex flex-col md:flex-row px-3 py-3 items-center w-full">

    <div v-if="showQuestion" class="w-full md:w-2/5 text-xs md:text-base question-text">
      {{ index === '' ? '' : index + 1 }}{{ index === '' ? '' : '.&nbsp;' }}{{ translator(clang, question) }}
    </div>

    <div class="w-full flex" :class="{ 'md:w-3/5': showQuestion }">
      <div v-if="role" class="w-full role md:w-2/5">
        {{ role }}
      </div>

      <div class="answer-row flex items-center flex-col w-full justify-start" :class="{ 'md:w-3/5': !!role }"
        v-if="!switchBars" @click="switchBars = !switchBars">
        <div class="question flex flex-row w-full justify-between">
          <div class="answer w-full flex flex-col">
            <div v-for="i in (question['intervals'].length - 1)" :key="`${i}-${question['intervals'][i]}`"
              class="flex flex-col justify-start">
              <template v-if="this.getCounts[i - 1] != 0 || windowWidth > 768">
                <div class="flex">
                  <div :title="translator(clang, question['steps']['steps'][i - 1])"
                    class="flex leading-6 items-center justify-center h-6 w-1/2 md:w-22 items-center"
                    :class="[`${i % 2 ? 'bg-indigo' : 'bg-red'}`]" :style="minBarWidth(question, i)">
                  </div>
                  <div class="flex text-gray-700 text-xs ml-1 md:ml-0 w-1/2 md:w-22 pl-2 items-center truncate">
                    {{ ((this.getCounts[i - 1] / (this.answers || question['answers']).length) * 100).toFixed(1) }}%
                    ({{ this.getCounts[i - 1] }})
                    {{ translator(clang, question['steps']['steps'][i - 1]) }}
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="answer-row flex flex-row w-full" :class="{ 'md:w-3/5': !!role }"
        @click="switchBars = !switchBars">
        <BarChart style="flex: 1;" :value="parseFloat(getAverage)"
          :max="question['intervals'][question['intervals'].length - 1]" />
      </div>
    </div>
  </div>
</template>

<script>
import translator from "../mixins/translator";
import BarChart from "./BarChart.vue";

export default {
  name: 'ScaleQuestionResult',
  components: {
    BarChart
  },
  props: {
    'question': {
      required: true
    },
    'index': {
      required: true
    },
    'clang': {
      required: true
    },
    'average': { //  For 360 survey
      default: false
    },
    'showQuestion': { //  For 360 survey
      default: true
    },
    'answers': { //  For 360 survey
      required: false
    },
    'role': { //  For 360 survey
      required: false
    },
    'counts': { //  For 360 survey
      required: false
    },
  },
  mixins: [translator],
  data() {
    return {
      windowWidth: window.innerWidth,
      switchBars: true,
    }
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth
    }
  },
  methods: {
    minBarWidth(question, i) {
      const w = ((this.getCounts[i - 1] / (this.answers || question['answers']).length) * 100).toFixed(1)
      return w != 0 ? 'width: ' + w + '%' : 'width: 2px'
    },
    barColor(i) {
      if (this.index !== '') {
        return this.index % 2 ? 'bg-indigo' : 'bg-red'
      }
      return i % 2 ? 'bg-indigo' : 'bg-red'
    },
    averageBySteps(q) {
      return `width: ${(this.getAverage * 100) / q['intervals'][q['intervals'].length - 1]}%`
    },
  },
  computed: {
    getCounts() {
      return this.counts || this.question['counts']
    },
    getAverage() {
      return (this.average || this.question['average']).toFixed(1)
    }
  }
}

</script>

<style lang="scss">
.scale-question-result {
  .question-text {
    font-size: 14px;
    margin-bottom: 5px;
  }

  .text-capitalize {
    text-transform: capitalize;
  }

  .answer-row {
    margin-bottom: 1px;
  }

  .role {
    font-size: 14px;
  }
}
</style>
