export default {
  "fi": `
    <p>Rekisterinpitäjän vastuuhenkilö: <br />info@halsa.fi</p>
    <h2>Käyttäjätietojen tallentamisesta</h2>
    <p>
      Käyttäjien tiedot sisältävät etu- ja sukunimen lisäksi sähköpostin ja salasanan. Salasana on salattu
      tietokantaan niin, että sitä ei ole mahdollista lukea jälkeenpäin edes palvelun ylläpitäjän toimesta
      (hash-funktio). Käyttäjien tietoja ei luovuteta eteenpäin. Käyttäjätiedot ovat saatavilla ainoastaan
      organisaation ylläpitäjille, Selfleaderin valmentajille ja henkilökunnalle.
    </p>
    <p>
      Käyttäjien tiedot säilytämme niin kauan kuin käyttäjätili halutaan pitää aktiivisena. Erillisestä pyynnöstä
      rekisterinpitäjälle voimme poistaa käyttäjätiedot anonymisoimalla datan.
    </p>
    <h2>Evästeistä</h2>
    <p> Palvelu käyttää evästeitä sisäänkirjautumisen ja tiettyjen turvatoimintojen (mm. CSRF-esto)
      mahdollistamiseksi. Ulkopuolisia evästeitä, Google-analytiikkaa tai muuta tracking-teknologiaa ei
      käytetä palvelussa.
    </p>
    <h2>Palvelimista</h2>
    <ul>
      <li>Sijaitsevat pääkaupunkiseudulla Suomessa</li>
      <li>Palvelinresursseista vastaa UpCloud. Lisätietoa heidän palvelustaan <a href= 'www.upcloud.fi'>
        saa heidän omilta kotisivuiltaan</a>.</li>
    </ul>
    <h2>Tekniikasta</h2>
    <ul>
      <li>Kaikki liikenne on salattua selaimen ja taustajärjetelmän välillä (HTTPS).</li>
      <li>Sähköpostit lähetetään luotetun kolmannen osapuolen kautta (Sendgrid).</li>
      <li>Kaikki käyttäjätiedot ja annetut vastaukset säilytetään turvatussa tietokannassa, johon ei ole
        pääsyä palvelininfrastruktuurin ulkopuolelta. </li>
    </ul>
    <h2>Pääsy infraan ja tietoihin</h2>
    <ul>
      <li>Montel Intergalactic Oy vastaa palveluiden toiminnasta ja kehityksestä</li>
      <li>Kehittäjätiimillä on etäpääsy palvelimille ja oikeudet tietokannan hallintaan</li>
      <li>UpCloud vastaa palvelimien fyysisestä turvallisuudesta</li>
    </ul>
    <h2>Varmuuskopiot</h2>
    <ul>
      <li>Tietokannan sisällöstä otetaan varmuuskopio päivittäin. Varmuuskopio säilötään palvelinsalin
        ulkopuolella turvasyistä. Ainoastaan Montelin kehittäjillä on pääsy varmuuskopioon</li>
      <li>Tietokanta on myös replikoitu käyttövarmuuden ja luotettavuuden vuoksi</li>
    </ul>
    `,
  "en": `
    <p>Data controller: <br />info@halsa.fi</p>
    <h2>About user data</h2>
    <p>
    User data includes first and last name, as well as email and password. The password is encrypted
    in the database in such a way that it is not possible to read it afterwards, not even by the service administrator
    (the password has gone through a hash function). User data is not disclosed to third parties. User data is only accessible to
    organization administrators, Selfleader coaches, and staff.
    </p>
    <p>
    We retain user data for as long as the user account is desired to be active. Upon separate request
    to the data controller, we can delete the data by anonymizing it.
    </p>
    <h2>Cookies</h2>
    <p> The service uses cookies to enable login and certain security features (e.g., CSRF protection).
    External cookies, Google Analytics, or other tracking technologies are not used in the service.
    </p>
    <h2>Servers</h2>
    <ul>
    <li>Located in the Helsinki metropolitan area, Finland</li>
    <li>Server resources are provided by UpCloud. More information about their service can be found on
        <a href='www.upcloud.fi'>their own website</a>.</li>
    </ul>
    <h2>Technology</h2>
    <ul>
    <li>All traffic is encrypted between the browser and the backend system (HTTPS).</li>
    <li>Emails are sent through a trusted third-party service (Sendgrid).</li>
    <li>All user data and provided responses are stored in a secure database inaccessible from
        outside the server infrastructure. </li>
    </ul>
    <h2>Access to infrastructure and data</h2>
    <ul>
    <li>Montel Intergalactic Oy is responsible for the operation and development of the services</li>
    <li>Development team has remote access to servers and rights to manage the database</li>
    <li>UpCloud is responsible for the physical security of the servers</li>
    </ul>
    <h2>Backups</h2>
    <ul>
    <li>Backups of the database contents are taken daily. The backup is stored outside the server room
        for security reasons. Only Montel developers have access to the backup.</li>
    <li>The database is also replicated for resilience and reliability.</li>
    </ul>
    `,
  "sv": `
    <p>Registeransvarig: <br />info@halsa.fi</p>
    <h2>Om användardata</h2>
    <p>
    Användardata inkluderar för- och efternamn, e-post och lösenord. Lösenordet är krypterat
    i databasen på ett sådant sätt att det inte går att läsa det senare, inte ens av tjänsteadministratören
    (lösenordet har gått igenom en hash-funktion). Användardata lämnas inte ut till tredje part. Användardata är endast tillgänglig för
    organisationsadministratörer, Selfleader coacher och personal.
    </p>
    <p>
    Vi behåller användardata så länge användarkontot önskas vara aktivt. Vid separat begäran
    till registeransvarig kan vi radera data genom att anonymisera den.
    </p>
    <h2>Cookies</h2>
    <p> Tjänsten använder cookies för att möjliggöra inloggning och vissa säkerhetsfunktioner (t.ex. CSRF-skydd).
    Andra cookies, Google Analytics eller annan spårningsteknik används inte i tjänsten.
    </p>
    <h2>Servrar</h2>
    <ul>
    <li>Belägna i Helsingfors storstadsområde, Finland</li>
    <li>Serverresurser tillhandahålls av UpCloud. Mer information om deras tjänst finns på
        <a href='www.upcloud.fi'>deras egna webbplats</a>.</li>
    </ul>
    <h2>Teknologi</h2>
    <ul>
    <li>All trafik är krypterad mellan webbläsaren och backend-systemet (HTTPS).</li>
    <li>E-post skickas via en betrodd tredjepartstjänst (Sendgrid).</li>
    <li>All användardata och tillhandahållna svar lagras i en säker databas otillgänglig från
        utanför serverinfrastrukturen. </li>
    </ul>
    <h2>Åtkomst till infrastruktur och data</h2>
    <ul>
    <li>Montel Intergalactic Oy ansvarar för drift och utveckling av tjänsterna</li>
    <li>Utvecklingsteam har fjärråtkomst till servrar och rättigheter att hantera databasen</li>
    <li>UpCloud ansvarar för den fysiska säkerheten av servrarna</li>
    </ul>
    <h2>Säkerhetskopior</h2>
    <ul>
    <li>Säkerhetskopior av databasens innehåll tas dagligen. Säkerhetskopian lagras utanför serverrummet
        av säkerhetsskäl. Endast Montels utvecklare har åtkomst till säkerhetskopian.</li>
    <li>Databasen är också replikerad för tålighet och tillförlitlighet.</li>
    </ul>
    `
}
