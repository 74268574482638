<template>
  <div class="flex flex-col w-full justify-between" :class="{'bg-gray-200': index % 2}">
    <div class="flex flex-col md:flex-row px-3 py-5 items-center w-full justify-between">
      <div class="w-full mb-2 md:mb-0 md:w-2/5 text-xs md:text-base">
        <template v-if="showQuestion">
          {{ index === '' ? '' : index + 1 }}{{ index === '' ? '' : '.&nbsp;' }}{{ translator(clang, question) }}
        </template>
      </div>
      <div v-if="role" class="flex flex-column w-1/5 text-xs text-capitalize">{{ role }}</div>
      <div class="flex items-center flex-col w-full md:w-3/5 justify-between print-hide">
        <div class="p-1 w-full shadow-lg	flex items-center justify-between cursor-pointer" v-show="!singleAnswer"
             :class="{'bg-white': index % 2, 'bg-gray-200': !(index % 2)}" @click="showAnswers = !showAnswers">
          <span class="px-2">
            {{ $t('default.user_results.amount_open_answers', { 'amount': getAnswers.length }) }}
          </span>
          <img src="../assets/svg/plus.svg" alt="">
        </div>
        <div v-if="singleAnswer" class="w-4/5 p-1 w-full">
          <div class="question flex flex-col w-full justify-center items-center">
            <p class="text-2xl text-black-900 font-display font-bold">
              {{getAnswers[0]['value']}}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div v-show="showAnswers" class="transition-transform print-show"
         v-for="answer in getAnswers" :key="answer['id']">
      <p class="p-2 mx-2 my-2 overflow-auto" :class="{'bg-gray-200': index % 2}">{{ answer['value'] }}</p>
    </div>
  </div>

</template>

<script>

import translator from "../mixins/translator";

export default {
  name: 'OpenQuestionResult',
  props: {
    'question': {
      required: true
    },
    'index': {
      required: true
    },
    'clang': {
      required: true
    },
    'showQuestion': { //  For 360 survey
      default: true
    },
    'answers': { //  For 360 survey
      required: false
    },
    'role': { //  For 360 survey
      required: false
    },
    'singleAnswer': {
      required: false
    }
  },
  mixins: [translator],
  data() {
    return {
      showAnswers: false
    }
  },
  computed: {
    getAnswers() {
      return this.answers || this.question['answers']
    }
  }
}

</script>
