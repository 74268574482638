<template>
  <div class="main-view-container">
    <span v-if="showBackIcon" class="back-icon absolute cursor-pointer hidden md:block" @click="goBack()">
      <svg class="w-10 h-10 md:w-8 md:h-8 lg:w-12 lg:h-12" viewBox="0 0 48 48" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M22 30L16 24M16 24L22 18M16 24H32M6 24C6 21.6362 6.46558 19.2956 7.37017 17.1117C8.27475 14.9278 9.60062 12.9435 11.2721 11.2721C12.9435 9.60062 14.9278 8.27475 17.1117 7.37017C19.2956 6.46558 21.6362 6 24 6C26.3638 6 28.7044 6.46558 30.8883 7.37017C33.0722 8.27475 35.0565 9.60062 36.7279 11.2721C38.3994 12.9435 39.7252 14.9278 40.6298 17.1117C41.5344 19.2956 42 21.6362 42 24C42 28.7739 40.1036 33.3523 36.7279 36.7279C33.3523 40.1036 28.7739 42 24 42C19.2261 42 14.6477 40.1036 11.2721 36.7279C7.89642 33.3523 6 28.7739 6 24Z"
          stroke="#3F3F46" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </span>
    <main :class="{ 'has-back-icon': showBackIcon }" class="grid grid-cols-12 gap-1 relative">
      <slot></slot>
    </main>
  </div>
</template>

<script>
import { onMounted, reactive, computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'

export default {
  props: {
    showBackIcon: {
      default: false
    },
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const fetchUser = () => store.dispatch('user/fetchUser')
    const state = reactive({
      user: computed(() => store.state.user.user)
    })

    onMounted(() => {
      fetchUser().then(() => {
        if (state.user && state.user.type['type'] === 'org_user' && route.name === 'dashboard') {
          router.push(`/user/${state.user.id}`)
        }
      })
    })
  },
  methods: {
    goBack() {
      console.log("go back")
      window.history.back()
    }
  }
}
</script>

<style lang="scss">
.main-view-container {
  margin: 2rem auto;
  padding: 0 2.5rem;
  max-width: 1600px;

  .back-icon {
    margin-top: 2rem;
    z-index: 5;
  }

  main {
    width: 100%;
  }

  @media screen and (min-width: 768px) {
    main.has-back-icon {
      padding-left: 80px;
    }
  }
}
</style>