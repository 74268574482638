<template>
  <div v-if="validationTestCompleted">
    <AuthContainer v-if="!wrongToken">
      <form class="w-full max-w-sm">
        <div>
          <h1 class="
              text-2xl text-gray-500
              font-display font-bold
              xl:text-2xl
              text-left
              mb-5
              font-heading
            ">
            {{ $t("default.password_reset.new_password_heading") }}:
          </h1>
        </div>

        <div class="md:flex md:items-center">
          <div class="md:w-1/3">
            <label class="block text-gray-500 font-bold text-left mb-1 md:mb-0 pr-4" for="inline-password">
              {{ $t("default.login.password") }}
            </label>
          </div>
          <div class="md:w-2/3">
            <input class="
                bg-gray-300
                appearance-none
                border-2 border-gray-200
                rounded
                w-fulll
                py-1
                px-2
                md:py-2 md:px-4
                text-gray-700
                leading-tight
                focus:outline-none focus:bg-white focus:border-indigo-500
              " id="inline-password" type="password" placeholder="" v-model="password" @keyup.enter="loginUser" />
          </div>
        </div>
        <div class="md:flex md:items-center mb-5 mt-2"></div>
        <div class="md:flex md:items-center mb-5 mt-2" v-if="errors">
          <div class="md:w-1/3"></div>
          <div class="md:w-2/3">
            <div class="text-xs font-display font-semibold text-red text-left">
              {{ errors }}
            </div>
          </div>
        </div>

        <div class="md:flex md:items-center">
          <div class="md:w-1/3"></div>
          <div class="md:w-2/3">
            <button class="
                bg-green
                text-gray-100
                py-1
                px-2
                md:py-2 md:px-4
                w-full
                rounded-full
                tracking-wide
                font-semibold font-display
                focus:outline-none focus:shadow-outline
                hover:bg-green-dark
                shadow-lg
                flex
                justify-around
                items-center
              " type="button" @click="confirmPassword()">
              <img src="../assets/svg/tail-spin.svg" alt="" class="h-6" v-if="apiRequestPending" />
              {{ $t("default.password_reset.confirm_text") }}
            </button>
          </div>
        </div>

        <div style="visibility: hidden" class="md:flex mt-4 md:items-center">
          <div class="md:w-1/5"></div>
          <div class="md:w-4/5">
            <button class="
                bg-red
                text-gray-100
                py-1
                px-2
                md:py-2 md:px-4
                w-full
                rounded-fulll
                tracking-wide
                font-semibold font-display
                focus:outline-none focus:shadow-outline
                hover:bg-red-dark
                shadow-lg
              " type="button">
              {{ $t("default.login.office_login") }}
            </button>
          </div>
        </div>
      </form>
    </AuthContainer>
    <div v-else>
      <h1 class="
          col-span-10
          text-center
          md:col-span-5
          text-1xl
          md:text-4xl
          text-black-900
          font-display font-bold
          xl:text-5xl
          text-left
          xl:text-bold
          font-heading
        ">
        {{ $t("default.password_reset.validation_error") }}
      </h1>
    </div>
  </div>
</template>

<script>
import AuthContainer from "../components/AuthContainer";
import axios from "axios";

export default {
  name: "ConfirmNewPassword",
  data() {
    return {
      email: "",
      password: "",
      apiRequestPending: false,
      errors: null,
      wrongToken: false,
      validationTestCompleted: false
    };
  },
  components: {
    AuthContainer,
  },
  created() {
    axios
      .get(`/auth/reset-token/verify/?reset_token=${this.$route.query.token}`)
      .catch((error) => {
        this.wrongToken = true
      }).finally(() => {
        this.validationTestCompleted = true
      })
  },
  methods: {
    confirmPassword() {
      let self = this;
      if (self.password) {
        axios
          .post("/auth/password/reset/", {
            password: this.password,
            reset_token: this.$route.query.token,
          })
        .then(function (response) {
          self.$router.push({
            path: "/login/",
          });
        })
        .catch((error) => {
          this.errors = error.response.data.detail;
        });
      }
      else {
        this.errors = this.$t("default.password_reset.empty_error");
      }
    },
  },
};
</script>
