{
  "common": {
    "dashboard": "Dashboard",
    "survey": "Survey",
    "survey360": "Survey 360",
    "print": "Print"
  },
  "dashboard": {
    "hello": "Welcome to selfleader.io",
    "learn": "Learn about selfleader.io",
    "greetings": "Great to see you, {name}!",
    "topic1": "Groups inside {org}",
    "topic2": "Groups",
    "new_group": "Create a new group",
    "survey": "Surveys",
    "send_survey": "Send out surveys",
    "view": "View",
    "survey_table_name": "Name",
    "survey_table_sent_to": "Sent to",
    "survey_table_answered": "Answered",
    "invite_people": "Invite more people",
    "group_description": "{members_count} members, {invited_count} invited in total",
    "members": "Members",
    "invited_total": "Invited in total",
    "invited": "Invited",
    "view_members": "View members",
    "invitations": "Invitations",
    "send_invitation_reminders": "Send invitation reminders",
    "user_email": "User Email",
    "invitation_status": "Invitation Status",
    "first_email_sent_at": "First email sent at",
    "reminder_sent_at": "Reminder sent at",
    "send_invitations": "Send invitation reminder",
    "invite": "Remind",
    "okay": "Okay",
    "reminder_successful": "Invitation reminder(s) sent successfully.",
    "organization_group_name": "Group",
    "survey_table_date": "Date",
    "survey_details": "Survey Details",
    "user_name": "User Name",
    "survey_sent": "Survey sent",
    "survey_status": "Survey status",
    "send_survey_reminder": "Send Survey Reminder",
    "send_survey_reminders": "Send reminders",
    "survey_name": "Survey Name",
    "survey_360": "Survey 360",
    "scheduled": "Scheduled",
    "download_org_report": "Download PowerPoint report"
  },
  "invite": {
    "invite_text": "Invite your colleagues",
    "invite_text2": "Give us a list of emails and we’ll send an invite to your colleagues to join Selfleader.io. One email per line, please.",
    "emails": "Emails",
    "emails_sub_text": "One email per line",
    "invite_in_group": "Invite into a group",
    "new_group_will_be_created": "Group {newGroup} will be created automatically",
    "group_select_placeholder": "Optionally select a group to add the invited people in. You can select from existing or just write a group name to create a new one.",
    "invitation_button": "Send invitations",
    "emails_error1": "Please add 1 or more valid emails",
    "emails_error2": "Please review emails(s), some of them seems to be incorrect.",
    "selected_group_missing": "Please select or add a new group for the new user(s).",
    "emails_success": "Invitation(s) sent successfully.",
    "success": "Success",
    "starting_survey": "Starting survey",
    "survey_sub_text": "This will be sent automatically when user accepts the invitation",
    "no_survey_selected": "No survey selected",
    "new_group": "New group",
    "existing_group": "Existing group",
    "email_greeting": "Email greeting",
    "email_greeting_help": "Optional greeting that will be added to the invitation email",
    "email_send_successful": "Successfully sent",
    "please_check_addresses": "Please check the addresses. ",
    "email_sending_failed": "Failed to send email to",
    "already_members": "These are already members",
    "invitation_already_sent": "Invitation is already sent to these emails",
    "errors": "error(s) occured during sending invitations:",
    "following": "Following ",
    "invite_multiple_members": "Search for recipients from the organization members or add them by email",
    "send_out_surveys": "Send the '{surveyName}' survey to '{number}' recipients. They Will recieve a link in their email that they can use to answer the survey",
    "schedule_success_message": "Invitation(s) have been scheduled for",
    "schedule_update": "Invitations will be scheduled for",
    "schedule_update_heading": "Updated (press x to cancel)",
    "schedule_tooltip": "Set invites schedule"
  },
  "signup": {
    "message": "<p>You can change the answer language from the drop-down menu in the upper right corner.</p> <p>Create a user account to access the Selfleader online material, surveys and your results.</p> <ol><li>Add your first and last name</li> <li>Choose your own password</li> <li>Next time you log in, your email will work as your username and the password you just set</li></ol>"
  },
  "login": {
    "no_account": "Don't have a user account yet?",
    "learn": "Create a user account to access the Selfleader online material, surveys and your future results.",
    "forget_password": "Forgot your password? Don’t worry.",
    "message": "<p>You can change the answer language from the drop-down menu in the upper right corner.</p> <p>Create a user account to access the Selfleader online material, surveys and your results.</p> <ol><li>Add your first and last name</li> <li>Choose your own password</li> <li>Next time you log in, your email will work as your username and the password you just set</li></ol>",
    "new_password": "Get a new one here",
    "login_text": "Log In",
    "office_login": "Login with Office 365",
    "email": "Email",
    "password": "Password",
    "first_name": "First Name",
    "last_name": "Last Name",
    "error1": "Please enter a valid email",
    "error2": "Please complete the form"
  },
  "password_reset": {
    "reset_text": "Reset Password",
    "reset_heading": "Reset your password",
    "new_password_heading": "Enter new password",
    "confirm_text": "Confirm",
    "validation_error": "Your token is expired, invalid or used.",
    "empty_error": "Password can not be empty",
    "success_message": "Password reset mail has been successfully sent to your email",
    "done": "Done",
    "success_button": "Login"
  },
  "members": {
    "members": "Members",
    "invite": "Invite new members",
    "survey": "Surveys",
    "send_survey": "Send out surveys",
    "survey_table_name": "Name",
    "survey_table_sent_to": "Sent to",
    "survey_table_answered": "Answered"
  },
  "survey": {
    "results": "Results",
    "answers": "Answers by",
    "invited": "Invited",
    "send_to_more": "Send to more people",
    "no_data": "No data",
    "summary": "Survey overall results",
    "strongest": "Strongest",
    "weakest": "Weakest",
    "all_members": "All members",
    "add_recipients": "Add",
    "selected_recipients": "Selected Recipients",
    "no_recipients": "No recipients Selected"
  },
  "send_survey": {
    "send_survey_text": "Send a survey",
    "survey": "Survey",
    "survey_questions": "'{survey_name}' contains {total_questions} questions",
    "recipients": "Recipients",
    "group": "Group",
    "greeting_text_message": "This message will be added to the email",
    "no_members": "No member in the selected group",
    "send_surveys": "Send out surveys",
    "no_member_selected": "No member selected",
    "success_message": "Survey sent!",
    "success_text": "Your recipients will receive a link to their inboxes shortly. You can keep watch on who has answered on the dashboard page.",
    "success_button_text": "Take me to the dashboard page",
    "all_members": "All Members",
    "limited_to_groups": "Limited to Groups",
    "already_in_selected_recipients": "This member already exist in the Selected Recipients list",
    "additional_message": "Additional message",
    "additional_message_subtext": "This message will be added to the email",
    "available": "Available Recipients",
    "new_user": "new user"
  },
  "404": {
    "page_not_found": "oops! Page not found",
    "page_not_exists": "Oops! The page you are looking for does not exist. It might have been moved or deleted."
  },
  "answer_survey": {
    "submit": "Submit survey",
    "no_survey": "Sorry, survey unavailable",
    "thanks": "Thank you for taking the time to complete this survey",
    "go_frontpage": "Go to the frontpage"
  },
  "user_results": {
    "your_surveys": "Your Answered Surveys",
    "received_invitations": "Received invitations",
    "answer": "Answer the survey",
    "answered_surveys": "Answered surveys by {name}",
    "no_answered_surveys": "No answers",
    "no_tokens": "No invitations for surveys at this time",
    "no_survey": "Your answered surveys and invitations to new surveys will appear here. No need to keep checking, we'll send you the invitations via email as well.",
    "no_survey_for_admin": "This user has not answered a survey.",
    "survey": "Survey ",
    "answers": "Your Answers",
    "amount_open_answers": "{amount} open answers",
    "chosen": "Chosen:",
    "promoters": "Promoters",
    "passives": "Passives",
    "detractors": "Detractors",
    "your_tasks_to_do": "Your tasks to do",
    "active_goals": "Active goals",
    "mark_task_incomplete": "Mark the task incomplete",
    "mark_goal_incomplete": "Mark the goal incomplete",
    "mark_task_complete": "Mark the task complete",
    "mark_goal_complete": "Mark the goal complete",
    "goal_history": "History",
    "task_instructions": "Please give any feedback on completing the task. This will be visible to you and your coach.",
    "goal_instructions": "Please give any feedback on completing reaching the goal. This will be visible to you and your coach.",
    "you_completed_task": "You completed this task on <strong>{completedAt}</strong>. With comments:",
    "no_goal_completions": "You haven't completed the goal yet",
    "no_goals_available_yet": "No goals available yet",
    "no_tasks_available_yet": "No tasks assigned yet"
  },
  "survey360": {
    "dropdown_text": "Role",
    "details_dropdown_text": "All",
    "target_user": "Target user"
  },
  "navigation": {
    "organization": "Organization",
    "invite": "Invite more members",
    "allMembers": "Members",
    "survey-reminder": "Send reminders",
    "userResults": "My page",
    "sendSurvey": "Send survey",
    "surveyView": "Survey",
    "members": "Members",
    "member-results": "Member results",
    "surveyResults": "Survey results"
  },
  "data-protection": {
    "title": "Data protection"
  }
}