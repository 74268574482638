{
  "common": {
    "dashboard": "Startsida",
    "survey": "Undersökning",
    "survey360": "Undersökning 360",
    "print": "Skriva ut"
  },
  "dashboard": {
    "hello": "Välkommen till Selfleader tjänsten",
    "learn": "Läs mera om tjänsten",
    "greetings": "Trevligt att ses, {name}!",
    "topic1": "Grupper i {org}",
    "topic2": "Grupper",
    "new_group": "Skapa en ny grupp",
    "survey": "Undersökning",
    "send_survey": "Skicka undersökning",
    "view": "Visa",
    "survey_table_name": "Namn",
    "survey_table_sent_to": "Skickat till",
    "survey_table_answered": "Svarande",
    "invite_people": "Bjud in flera",
    "group_description": "{members_count} medlemmar, {invited_count} inbjudna totalt",
    "invited_total": "Inbjudna totalt",
    "members": "Medlemmar",
    "invited": "Inbjudna",
    "view_members": "Visa medlemmar",
    "invitations": "Inbjudningar",
    "send_invitation_reminders": "Skicka påminnelse om inbjudan",
    "user_email": "Användarens e-mail",
    "invitation_status": "Inbudningsstatus",
    "first_email_sent_at": "Första e-mailet sänt till",
    "reminder_sent_at": "Påminnelse skickat kl.",
    "send_invitations": "Skicka inbjudningar",
    "invite": "Bjud in",
    "okay": "OK",
    "reminder_successful": "Påminnelser sända.",
    "organization_group_name": "Organisationens namn",
    "survey_table_date": "Datum",
    "survey_details": "information om undersökningen",
    "user_name": "Användarnamn",
    "survey_sent": "Undersökning sänt",
    "survey_status": "Undersökningens status",
    "send_survey_reminder": "Skicka påminnelse om undersökning",
    "send_survey_reminders": "Skicka påminnelser om undersökning",
    "survey_name": "Undersökningens namn",
    "survey_360": "Undersökning 360",
    "scheduled": "Bokad",
    "download_org_report": "Ladda ner PowerPoint"
  },
  "invite": {
    "invite_text": "Bjud in dina kollegor",
    "invite_text2": "Fyll i en lista med e-postmeddelanden så skickar vi en inbjudan till dina kollegor att gå med i tjänsten. Ett mejl per rad, tack.",
    "emails": "e-mails",
    "emails_sub_text": "Ett mejl per rad, tack.",
    "invite_in_group": "Bjud in till gruppen",
    "new_group_will_be_created": "Gruppen skapas automatiskt",
    "group_select_placeholder": "Välj den grupp som de inbjudna personerna ska läggas till i. Du kan välja bland befintliga eller ange ett gruppnamn för att skapa ett nytt.",
    "invitation_button": "Skicka inbjudningar",
    "emails_error1": "Lägg till ett eller flera e-postmeddelanden.",
    "emails_error2": "Kontrollera e-postmeddelandena, några av dem kan vara felaktiga.",
    "selected_group_missing": "V älj eller lägg till en ny grupp för de nya användarna.",
    "emails_success": "Inbjudningar har skickats.",
    "success": "Lyckades",
    "starting_survey": "Start undersökning",
    "survey_sub_text": "Den här undersökningen skickas till användare så snart de ansluter sig till tjänsten",
    "no_survey_selected": "Ingen undersökning har valts",
    "new_group": "Ny grupp",
    "existing_group": "En befintlig grupp",
    "email_greeting": "Hälsning",
    "email_greeting_help": "Ett alternativt meddelande som läggs till som en del av det första e-postmeddelandet",
    "email_sending_failed": "Det gick inte att skicka e-mail till adressen",
    "please_check_addresses": "Kontrollera adresserna",
    "already_members": "Redan medlemmar",
    "invitation_already_sent": "Inbjudan har redan skickats till dessa e-postmeddelanden",
    "errors": "Fel",
    "following": "Följä",
    "invite_multiple_members": "Sök efter mottagare från organisationsmedlemmar eller lägg till dem via e-post",
    "send_out_surveys": "Skicka enkäten '{surveyName}' till '{number}' mottagare. De kommer att få en länk i sin e-post som de kan använda för att svara på enkäten",
    "email_send_successful": "Undersökning sänt",
    "schedule_success_message": "Inbjudan är planerad",
    "schedule_update": "Inbjudningar är schemalagda",
    "schedule_update_heading": "Uppdaterad",
    "schedule_tooltip": "Sätt ett schema för inbjudningar"
  },
  "signup": {
    "message": "<p>Oikealta ylhäältä pääset vaihtamaan vastauskieltä Suomi/Ruotsi/Englanti</p> <p>Skapa ett användarkonto och få tillgång till Selfleaders material, bedömningar och dina framtida resultat</p> <ol> <li>Lägg till ditt för- och efternamn</li> <li>Välj ett lämpligt lösenord</li> <li>Nästa gång du loggar in din e-post kommer att fungera som ditt användarnamn och lösenordet du just skapade. Skriv ner ditt lösenord</li></ol>"
  },
  "login": {
    "no_account": "Har du inget användarnamn än?",
    "learn": "Skapa ett användarkonto och få tillgång till Selfleaders material, bedömningar och dina framtida resultat.",
    "forget_password": "Glömde du ditt lösenord.",
    "new_password": "Du kan få ett nytt lösenord här.",
    "login_text": "Logga in",
    "office_login": "Logga in med Office 365",
    "email": "e-mail",
    "password": "Lösenord",
    "first_name": "Fornamn",
    "last_name": "Efternamn",
    "error1": "Vänligen ange en giltig e-mail",
    "error2": "Vänligen fyll i alla obligatoriska fält"
  },
  "password_reset": {
    "reset_text": "Återställ lösenord",
    "reset_heading": "Återställ lösenord",
    "new_password_heading": "Ange ett nytt lösenord",
    "confirm_text": "Bekräfta",
    "validation_error": "Användarnam är ogiltigt",
    "empty_error": "Lösenordet får inte vara tomt",
    "success_message": "Ett lösenordsåterställningsmeddelande har skickats till din e-post",
    "done": "Gjord",
    "success_button": "Logga in"
  },
  "members": {
    "members": "Medlemmar",
    "invite": "Bjud in nya medlemmar",
    "survey": "undersökning",
    "send_survey": "Skicka in unndersökningen",
    "survey_table_name": "Skickat",
    "survey_table_sent_to": "Skickat",
    "survey_table_answered": "Svarade"
  },
  "survey": {
    "results": " SVAREN",
    "answers": "DE SLAGADE",
    "invited": "INBJUDNA",
    "send_to_more": "Skicka till fler",
    "no_data": "Inga svar",
    "summary": "undersökning resultat",
    "strongest": "Starkaste",
    "weakest": "Svagaste",
    "all_members": "Alla användare i organisationen",
    "add_recipients": "Mer",
    "selected_recipients": "utvalda mottagare",
    "no_recipients": "Inga valda mottagare "
  },
  "send_survey": {
    "send_survey_text": "Skicka undersökning",
    "survey": "Undersökning",
    "survey_questions": "'{survey_name}' innehåller {total_questions} frågor",
    "recipients": "Mottagare",
    "group": "Grupp",
    "greeting_text_message": "Hälsningstextmeddelande.",
    "no_members": "Inga medlemmar i den valda gruppen",
    "send_surveys": "Skicka undersökning",
    "no_member_selected": "Inga valda ledamöter",
    "success_message": "Undersökning skickat",
    "success_text": "Dina mottagare kommer snart att få länken i sin e-mail.",
    "success_button_text": "Ta mig till dashboard",
    "all_members": "Alla medlemmar",
    "limited_to_groups": "Begränsa till grupper",
    "already_in_selected_recipients": "Den här medlemmen finns redan i listan valda mottagare",
    "additional_message": "Ytterligare meddelande",
    "additional_message_subtext": "Detta meddelande kommer att läggas till e-post",
    "available": "tillgängliga mottagare",
    "new_user": "Ny användare "
  },
  "404": {
    "page_not_found": "hoppsan! sidan hittas inte",
    "page_not_exists": "Hoppsan, sidan du letar efter finns inte."
  },
  "answer_survey": {
    "submit": "Skicka svar",
    "no_survey": "Tyvärr, inget undersökning tillgängligt",
    "thanks": "Tack",
    "go_frontpage": "Gå till tjänstens förstasida"
  },
  "user_results": {
    "your_surveys": "Svarade undersökning",
    "received_invitations": "Inbjudningar mottagna",
    "answer": "Svara på undersökningen",
    "answered_surveys": "Besvarade undersökningar av {name}",
    "no_answered_surveys": "Inga svar",
    "no_tokens": "Inga undersökning bjudningar tillgängliga just nu",
    "no_survey": "Undersökning du har svarat på kommer att visas här. Du behöver inte fortsätta kolla, vi skickar dig inbjudningarna via e-post också.",
    "no_survey_for_admin": "Den här användaren har inte svarat på undersökning ännu.",
    "survey": "Undersökning:",
    "answers": "Resultat",
    "amount_open_answers": "{amount} öppna svar",
    "chosen": "Valde:",
    "promoters": "Rekommendationer",
    "passives": "Passiva",
    "detractors": "Recensenter",
    "your_tasks_to_do": "Uppgifter som ska utföras",
    "active_goals": "Repetitiva övningar",
    "mark_task_incomplete": "Markera uppgiften som ofullständig",
    "mark_goal_incomplete": "Markera målet som ofullständigt",
    "mark_task_complete": "Markera uppgiften som uppnått",
    "mark_goal_complete": "Markera målet som uppnått",
    "goal_history": "History",
    "task_instructions": "Vänligen ge feedback när du har slutfört uppgiften.",
    "goal_instructions": "Vänligen ge feedback när du har slutfört uppgiften.",
    "you_completed_task": "Du slutförde den här uppgiften <strong>{completedAt}</strong>. Med kommentarer:",
    "no_goal_completions": "Du har inte uppnått ett enda mål än.",
    "no_goals_available_yet": "Inga uppsatta mål",
    "no_tasks_available_yet": "Inga tilldelade uppgifter "
  },
  "survey360": {
    "dropdown_text": "Rooli",
    "details_dropdown_text": "Allt",
    "target_user": "Målanvändare"
  }
}