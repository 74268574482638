export default {
    methods: {
        translator(clang = 'fi', questionArray) {
            // FIXME: this code has been repeated for a component and for a vuejs method
            //        refactor in a coherent implementation
            const reducedArray = {}
            let translatedQuestion = {}
            questionArray.translations.forEach(function (item) {
                reducedArray[`${item.locale}`] = item.text
            })
            if (reducedArray[clang] !== undefined) {
                translatedQuestion = reducedArray[clang]
            } else if (reducedArray['fi'] !== undefined) {
                translatedQuestion = reducedArray['fi']
            } else if (reducedArray['en'] !== undefined) {
                translatedQuestion = reducedArray['en']
            } else if (reducedArray['sv'] !== undefined) {
                translatedQuestion = reducedArray['sv']
            }
            return translatedQuestion
        }
    }
}
