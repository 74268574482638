<template>
  <MainViewContainer :showBackIcon="true">
    <div class="col-span-12 px-8 md:px-0 md:col-span-8 answer-survey">
      <div v-if="loading">
        <div class="grid grid-cols-3">
          <div class="col-start-2">
            <Loader />
          </div>
        </div>
      </div>

      <div v-if="no_survey">
        <h1>
          {{ $t('default.answer_survey.no_survey') }}
        </h1>
      </div>

      <div v-else-if="surveySubmitted === true">
        <h1>
          {{ $t('default.answer_survey.thanks') }}
        </h1>
        <router-link class="bg-green text-gray-100 py-1 px-2 md:py-2 md:px-4 md:w-2/5 w-3/4 rounded-full tracking-wide
                                                   font-semibold font-display focus:outline-none focus:shadow-outline hover:bg-green-dark shadow-lg flex
                                                   justify-around items-center mt-5 md:mt-14"
          :to="{ name: 'dashboard' }">
          {{ $t('default.answer_survey.go_frontpage') }}
        </router-link>
      </div>

      <div v-else-if="surveySubmitted === false" class="col-span-10 md:col-span-10">
        <h1>
          {{ survey_data.name }}
        </h1>

        <p class="greeting-text">
          {{ survey_data.greeting_text }}
        </p>

        <ul class="questions">
          <li class="grid grid-cols-12 grid-flow-col auto-cols-min"
            v-for="(value, index) in survey_data.survey_questions" :key="value.id">
            <div class="col-span-11">
              <FreeText v-if="value.question.type === 'FT'" :question="value" :current_lang="state.clan" :index="index"
                v-model:freetex_ans="all_answers[value.question.id]" :disabled="viewOnly" />
              <SelectionOption v-if="value.question.type === 'SO'" :question="value" :current_lang="state.clan"
                :index="index" v-model:select_ans="all_answers[value.question.id]" :disabled="viewOnly" />
              <Scale v-if="value.question.type === 'SC'" :question="value" :current_lang="state.clan" :index="index"
                v-model:sliderAns="all_answers[value.question.id]" :disabled="viewOnly" />
              <ShortText v-if="value.question.type === 'ST'" :question="value" :current_lang="state.clan" :index="index"
                v-model:shorttext_ans="all_answers[value.question.id]" :disabled="viewOnly" />
              <NetPromoterScore v-if="value.question.type === 'NPS'" :question="value" :current_lang="state.clan"
                :index="index" v-model:netpromAns="all_answers[value.question.id]" :disabled="viewOnly" />
            </div>
          </li>
        </ul>
        <div class="col-span-12 my-20 text-center">
          <button v-if="!viewOnly" class="big green" type="button" @click="submitSurveyAnswers()">
            {{ $t('default.answer_survey.submit') }}
          </button>
        </div>
      </div>
    </div>
  </MainViewContainer>
</template>

<script>
import MainViewContainer from '@/components/MainViewContainer';
import FreeText from '../components/Questions/FreeText';
import SelectionOption from '../components/Questions/SelectionOption';
import Scale from '../components/Questions/Scale';
import ShortText from '../components/Questions/ShortText';
import NetPromoterScore from "../components/Questions/NetPromoterScore";
import { useRoute } from 'vue-router';
import { onMounted, ref, reactive, computed } from 'vue';
import axios from 'axios'
import { useStore } from "vuex";
import Loader from "../components/Loader";

export default {
  name: 'AnswerSurvey',
  components: {
    FreeText,
    SelectionOption,
    Scale,
    ShortText,
    MainViewContainer,
    NetPromoterScore,
    Loader
  },
  data() {
    return {
      all_answers: {},
      surveySubmitted: false,
      token: this.survey_id,
    }
  },
  setup() {
    const route = useRoute()
    const viewOnly = ref(route.name === 'surveyView')
    const store = useStore()
    const survey_id = route.params.id
    const survey_data = ref({})
    const reactiveWidth = ref(screen.width)
    const loading = ref(true)
    const no_survey = ref(false)

    const state = reactive({
      clan: computed(() => store.state.survey.currentLanguage)
    })

    const fetchSurvey = () => {
      let url = `/api/v1/surveys/detail/?token=${survey_id}`
      if (viewOnly.value)
        url = `/api/v1/surveys/view/${survey_id}/`
      axios.get(url)
        .then((res) => {
          const validTranslations = res.data.translations.filter((trans) => trans.locale == store.state.survey.currentLanguage)
          const translation = validTranslations.length > 0 ? validTranslations[0] : res.data.translations[0]

          survey_data.value = {
            name: translation.name,
            description: translation.description,
            greeting_text: translation.greeting_text,
            ...res.data
          }

          loading.value = false
        }).catch((e) => {
          no_survey.value = true
          loading.value = false
        })
    }

    window.onresize = () => {
      reactiveWidth.value = window.innerWidth
    }

    onMounted(() => {
      fetchSurvey()
    })

    return {
      survey_id,
      survey_data,
      state,
      reactiveWidth,
      loading,
      fetchSurvey,
      no_survey,
      viewOnly
    }
  },
  computed: {
    isMobil() {
      if (this.reactiveWidth < 768) {
        return false
      }
      return true
    },

    currentLanguage() {
      return this.$store.state.survey.currentLanguage
    }
  },
  methods: {
    submitSurveyAnswers() {
      const responses = []
      let post_response = {}

      for (const [key, value] of Object.entries(this.all_answers)) {
        responses.push({ 'question_id': parseInt(key), 'answer': value })
      }
      let data = {
        'token': this.token,
        'responses': responses
      }

      if (!this.surveySubmitted) {
        axios.post('/api/v1/surveys/answer/', data, {
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json',
            'X-CSRFToken': document.querySelector('[name="csrfmiddlewaretoken"]').getAttribute('value')
          }
        })
          .then((res) => {
            post_response = res.data;
            this.surveySubmitted = true;
          })
          .catch((err) => {
            self.errors = err.response.data.map((e) => {
              if (e['non_field_errors']) return e['non_field_errors'][0]
            }).filter(e => !!e).toString()
          })
      }
      console.log('Submitting answers:', post_response);
    }
  },
  watch: {
    currentLanguage: {
      handler: function (newLang) {
        this.fetchSurvey()
      }
    }
  }

}
</script>

<style lang="scss">
.answer-survey {
  .greeting-text {
    white-space: pre-wrap;
    margin-top: 20px;
    margin-bottom: 40px;
  }

  .questions {
    li {
      margin-top: 3rem;
    }
  }
}
</style>