<template>
  <MainViewContainer :showBackIcon="true">
    <div class="col-span-12 md:col-start-2 md:col-span-10 xl:col-span-9 ">
      <div class="flex justify-between items-center mt-6">
        <h1>{{ orgName }}</h1>
        <button class="green small" @click="downloadReport" v-if="isAdminUser">
          {{ $t('default.dashboard.download_org_report') }}
          <img src="../assets/svg/presentation-chart-bar.svg" />
        </button>
      </div>

      <div class="my-10 grid grid-cols-12 gap-1">
        <div class="col-span-12">
          <table class="shadow-lg bg-white table-fixed w-full">
            <tbody>
              <tr class="bg-gray-100">
                <td class="w-1/2 px-2 py-2 md:px-8 md:py-4">{{ $t('default.dashboard.members') }}</td>
                <td class="w-1/5 py-4 text-right">{{ org.total_members }}</td>
                <td class="w-4/5 px-2 py-2 md:px-8 md:py-4 text-center w-1/5">
                  <a @click="$router.push({ name: 'allMembers', params: { org: org.id } })"
                    class="font-bold text-green hover:text-green-dark cursor-pointer">
                    {{ $t('default.dashboard.view_members') }}
                  </a>
                </td>
              </tr>
              <tr>
                <td class="w-1/2 px-2 py-2 md:px-8 md:py-4">{{ $t('default.dashboard.invited') }}</td>
                <td class="py-4 w-1/5 text-right">{{ org['total_invited'] - org['total_scheduled'] }}</td>
                <td class="w-4/5"></td>
              </tr>
              <tr>
                <td class="w-1/2 px-2 py-2 md:px-8 md:py-4">{{ $t('default.dashboard.scheduled') }}</td>
                <td class="py-4 w-1/5 text-right">{{ org['total_scheduled'] }}</td>
                <td class="w-4/5"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="flex items-center justify-between mt-20">
        <div class="md:w-1/3">
          <h2>{{ $t('default.dashboard.survey') }}</h2>
        </div>
        <div class="md:w-2/3 flex gap-4 justify-end" v-if="isAdminUser">
          <button class="green" type="button" @click="$router.push({ name: 'surveyDetails', params: { org: org.id } })">
            {{ $t('default.dashboard.send_survey_reminders') }}
          </button>
          <button class="green" type="button" @click="$router.push({ name: 'sendSurvey', params: { org: org.id } })">
            {{ $t('default.dashboard.send_survey') }}
          </button>
        </div>
      </div>

      <div class="mb-20 grid grid-cols-12 gap-1 mt-5">
        <div class="col-span-12">
          <table class="shadow-lg bg-white table-auto w-full">
            <tr class="font-bold text-lg">
              <th class="bg-gray-100 text-left px-2 py-2 md:px-8 md:py-4">{{
    $t('default.dashboard.survey_table_name')
  }}
              </th>
              <th class="bg-gray-100 text-right">{{ $t('default.dashboard.survey_table_sent_to') }}</th>
              <th class="bg-gray-100 text-right">{{ $t('default.dashboard.survey_table_answered') }}</th>
              <th class="bg-gray-100"></th>
            </tr>
            <tr v-for="(survey, i) in organizationSurveys[org.id]" :key="survey['id']"
              :class="i % 2 ? 'bg-gray-100' : ''">
              <td class="px-2 py-2 md:px-8 md:py-4">{{ survey['name'] }}</td>
              <td class="py-4 text-right">{{ survey['invited_count'] }}</td>
              <td class="py-4 text-right">{{ getAnsweredCount(survey) }}% ({{ survey['answered_count'] }})</td>
              <td class="px-2 py-2 md:px-8 md:py-4 text-center w-1/5"><a
                  @click="$router.push({ name: 'surveyResults', params: { org: org.id, id: survey['id'] } })"
                  class="font-bold text-green hover:text-green-dark cursor-pointer"> {{
    $t('default.dashboard.view')
  }}</a></td>
            </tr>
          </table>
        </div>
      </div>

      <div class="flex justify-between mt-6">
        <div class="md:w-2/3">
          <h2>{{ $t('default.dashboard.topic2') }}</h2>
        </div>
        <div class="md:w-2/3 flex justify-end">
          <button class="green" type="button" @click="$router.push({ name: 'invite', params: { org: org.id } })">
            {{ $t('default.dashboard.invite_people') }}
          </button>
        </div>
      </div>

      <div class="mb-20 grid grid-cols-12 gap-1 mt-5">
        <div class="col-span-12">
          <table class="shadow-lg bg-white table-auto w-full">
            <tr class="font-bold text-lg">
              <th class="bg-gray-100 text-left px-2 py-2 md:px-8 md:py-4">{{
    $t('default.dashboard.survey_table_name')
  }}
              </th>
              <th class="bg-gray-100 text-right">{{ $t('default.dashboard.members') }}</th>
              <th class="bg-gray-100 text-right">{{ $t('default.dashboard.invited_total') }}</th>
              <th class="bg-gray-100"></th>
            </tr>
            <tr v-for="(group, i) in org.groups" :key="group['id']" :class="i % 2 ? 'bg-gray-100' : ''">
              <td class="px-2 py-2 md:px-8 md:py-4">{{ group.name }}</td>
              <td class="py-4 text-right">{{ group.members_count }}</td>
              <td class="py-4 text-right">{{ group.invited_count }}</td>
              <td class="px-2 py-2 md:px-8 md:py-4 text-center w-1/5"><a
                  @click="$router.push({ name: 'members', params: { org: org.id, group: group.id } })"
                  class="font-bold text-green hover:text-green-dark cursor-pointer">
                  {{ $t('default.dashboard.view') }}</a></td>
            </tr>
          </table>
        </div>
      </div>

      <div class="flex items-center justify-between mt-10" v-if="isAdminUser">
        <div class="md:w-2/3">
          <h2>{{ $t('default.dashboard.survey_360') }}</h2>
        </div>
      </div>

      <div class="mb-40 grid grid-cols-12 gap-1 mt-5" v-if="isAdminUser">
        <div class="col-span-12">
          <table class="shadow-lg bg-white table-auto w-full">
            <tr class="font-bold text-lg">
              <th class="bg-gray-100 text-left px-2 py-2 md:px-8 md:py-4">
                {{ $t('default.survey360.target_user') }}
              </th>
              <th class="bg-gray-100 text-left px-2 py-2 md:px-8 md:py-4">
                {{ $t('default.common.survey') }}
              </th>
              <th class="bg-gray-100 text-right">{{ $t('default.dashboard.survey_table_sent_to') }}</th>
              <th class="bg-gray-100"></th>
            </tr>
            <tr v-for="(survey, i) in organizationSurvey360s" :key="survey['id']" :class="i % 2 ? 'bg-gray-100' : ''">
              <td class="px-2 py-2 md:px-8 md:py-4">{{ survey['target'] }}</td>
              <td class="px-2 py-2 md:px-8 md:py-4">{{ survey['survey'] }}</td>
              <td class="py-4 text-right">{{ survey['invited_count'] }}</td>
              <td class="px-2 py-2 md:px-8 md:py-4 text-center w-1/5"><a
                  @click="$router.push({ name: 'survey360Results', params: { org: org.id, id: survey['id'] } })"
                  class="font-bold text-green hover:text-green-dark cursor-pointer">{{ $t('default.dashboard.view') }}
                </a></td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </MainViewContainer>
</template>

<script>
import axios from '@/axios-instance'
import MainViewContainer from '@/components/MainViewContainer'

export default {
  name: "Organization",
  components: { MainViewContainer },
  props: {
    organization: {
      default: null
    },
  },

  mounted() {
    const currentRoute = this.$router.currentRoute.value
    const orgId = currentRoute.params.org || currentRoute.params.organization
    this.$store.dispatch('organization/fetchOrganization', orgId)
    this.$store.dispatch('survey/fetchForOrganization', orgId)
    this.$store.dispatch('survey360/fetchForOrganization', orgId)
  },

  computed: {
    isAdminUser() {
      return this.$store.getters['user/isAdminUser']
    },
    orgName() {
      return this.org.name
    },
    org() {
      return this.$store.state.organization?.organization || {}
    },
    organizationSurveys() {
      return this.$store.getters['survey/getOrganizationSurveys']
      // return this.$store.state.survey.organizations
    },
    organizationSurvey360s() {
      return this.$store.getters['survey360/getSurveys']
    },
  },

  methods: {
    getAnsweredCount(survey) {
      if (survey['answered_count'] === 0) {
        return 0
      }
      return ((survey['answered_count'] * 100) / survey['invited_count']).toFixed()
    },
    async downloadReport() {
      const currentRoute = this.$router.currentRoute.value
      const orgId = currentRoute.params.org || currentRoute.params.organization
      const reportUrl = `/api/v1/surveys/organization/${orgId}/pptreport/?format=ppt`

      try {
        await axios.post('/auth/user/set_cookie/')
        window.location.href = reportUrl
      } catch (e) {
        console.error('Error in setting cookie: ', e)
      }
    }
  }
}
</script>

<style scoped></style>
