<template>
  <SimpleModal @close="$emit('close')">
    <template #content>
      <h2>{{ userGoal.goal[`title_${$i18n.locale}`] }}</h2>
      <div v-html="userGoal.goal[`html_description_${$i18n.locale}`]"></div>


      <div class="history">
        <h3>
          {{ $t("default.user_results.goal_history") }}
        </h3>
        <ul v-if="userGoal.completions.length > 0">
          <li v-for="completion in userGoal.completions" :key="completion.id">
            {{ formatDate(completion.completed_at) }}: {{ completion.note }}
          </li>
        </ul>
        <p v-else>
          {{ $t("default.user_results.no_goal_completions") }}
        </p>
      </div>

      <template v-if="userGoal.completed === false">
        <p class="instructions">
          {{ $t("default.user_results.goal_instructions") }}
        </p>
        <textarea rows="5" v-model="state.feedback" class="bg-gray-300 appearance-none border-2 border-gray-200 rounded w-full py-1 px-2 md:py-2 md:px-4 text-gray-700
                  leading-tight focus:outline-none focus:bg-white focus:border-indigo-500"></textarea>
      </template>
    </template>
    <template #buttons>
      <button v-if="userGoal.completed === false" class="green" @click="complete()">
        {{ $t("default.user_results.mark_goal_complete") }}
      </button>
      <button v-else class="red" @click="undo()">
        {{ $t("default.user_results.mark_goal_incomplete") }}
      </button>
    </template>
  </SimpleModal>
</template>

<script>
import { reactive } from 'vue'
import SimpleModal from '@/components/SimpleModal'
import { parse, format } from 'date-fns'

export default {
  name: "GoalModal",
  components: {
    SimpleModal,
  },
  props: {
    userGoal: Object
  },
  setup() {
    const state = reactive({ feedback: '' })
    return {
      state
    }
  },
  methods: {
    complete() {
      this.$store.dispatch('tasksGoals/completeUserGoal', { userGoal: this.userGoal, feedback: this.state.feedback })
      this.$emit('close')
    },
    undo() {
      this.$store.dispatch('tasksGoals/undoUserGoal', { userGoal: this.userGoal, feedback: this.state.feedback })
      this.$emit('close')
    },
    formatDate(str) {
      const d = parse(str, "yyyy-MM-dd", new Date())
      return format(d, "dd.MM.yyyy")
    }
  },
  computed: {
    completedAt() {
      if (this.userGoal.completed === false) {
        return ""
      }
      const latestCompletion = this.userGoal.completions[0]
      const d = parse(latestCompletion.completed_at, "yyyy-MM-dd", new Date())
      return format(d, "dd.MM.yyyy")
    }
  }
}
</script>

<style scoped>
.history {
  margin: 40px 0;
}
</style>
