<template>
  <div class="survey-invites-table">

    <div class="flex md:items-center justify-between mt-10 py-8">
      <div class="md:w-2/3">
        <h2 class="text-1xl text-black-900 font-display lg:text-4xl text-left
        font-bold font-heading">{{ $t('default.dashboard.survey_details') }}</h2>
      </div>
      <div class="md:w-1/3" v-if="surveys && surveys.length">
        <button class="bg-green text-gray-100 py-1 px-2 md:py-2 md:px-4 w-full rounded-full tracking-wide
                                font-semibold font-display focus:outline-none focus:shadow-outline hover:bg-green-dark
                                shadow-lg flex justify-around items-center" type="button"
          @click="sendSurveyReminderToAll">
          {{ $t('default.dashboard.send_survey_reminders') }}
        </button>
      </div>
    </div>
    <div class="grid grid-cols-12 gap-1 mt-10">
      <div class="col-span-12 md:col-span-12">
        <table class="shadow-lg bg-white table-auto w-full">
          <tr class="bg-gray-100">
            <td class="px-8 py-4 font-semibold">{{ $t('default.dashboard.user_name') }}</td>
            <td class="px-8 py-4 font-semibold">{{ $t('default.dashboard.survey_name') }}</td>
            <td class="px-8 py-4 font-semibold">{{ $t('default.dashboard.survey_status') }}</td>
            <td class="px-8 py-4 font-semibold">{{ $t('default.dashboard.survey_sent') }}</td>
            <td class="px-8 py-4 font-semibold">{{ $t('default.dashboard.reminder_sent_at') }}</td>
            <td class="px-8 py-4 font-semibold">{{ $t('default.dashboard.send_survey_reminder') }}</td>
          </tr>
          <tr :class="i % 2 ? 'bg-gray-100' : ''" v-for="(survey, i) in surveys" :key="survey">
            <td class="px-8 py-4">
              {{ survey.user_name }}
            </td>
            <td class="px-8 py-4">
              {{ survey.survey_name }}
            </td>
            <td class="px-8 py-4">
              {{ survey.status }}
            </td>
            <td class="px-8 py-4">
              {{ formatedDatetime(survey.created) }}
            </td>
            <td class="px-8 py-4">
              {{ formatedDatetime(survey.second_reminder_sent_at ?
          survey.second_reminder_sent_at :
          (survey.first_reminder_sent_at ? survey.first_reminder_sent_at : '-')) }}
            </td>
            <td class="px-8 py-4 justify-around grid items-center">
              <div class="justify-items-center">
                <button class="px-8 py-4 font-bold text-green hover:text-green-dark cursor-pointer"
                  @click="sendSurveyReminder(survey.id)">
                  {{ $t('default.dashboard.invite') }}
                </button>
              </div>
            </td>
          </tr>
          <tr v-if="surveys.length == 0">
            <td colspan="10" class="no-content-yet">
              {{ $t('default.dashboard.no-reminders-sent') }}
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import axios from '@/axios-instance'
import { stringToDatetime } from '@/datetimeUtils'
import { useRoute } from 'vue-router'

const surveys = ref([])
const route = useRoute()
const fetchSurveys = () => {
  axios.get(`/api/v1/surveys/remind/?org=${route.params.org}`)
    .then((res) => {
      surveys.value = res.data
    }).catch((e) => {
      console.log(e)
    })
}

const sendSurveyReminderToAll = () => {
  let data = { "org_id": route.params.org }
  axios.post('/api/v1/surveys/remind/', data)
    .then(() => {
      fetchSurveys()
    })
}

const sendSurveyReminder = (id) => {
  let data = {
    'org_id': route.params.org,
    'token_id': id
  }
  axios.post('/api/v1/surveys/remind/', data)
    .then(() => {
      fetchSurveys()
    })
}

const formatedDatetime = (str) => {
  return stringToDatetime(str)
}

onMounted(() => {
  fetchSurveys()
})
</script>

<style lang="scss">
.survey-invites-table {

  .no-content-yet {
    line-height: 5rem;
    text-align: center;
  }
}
</style>