import axios from '@/axios-instance'

export const ModuleOrganizations = {
  namespaced: true,

  state: {
    organizations: null
  },

  getters: {
  },

  mutations: {
    setOrganizations(state, data) {
      state.organizations = data
    }
  },

  actions: {
    fetchOrganizations(context) {
      return axios.get('/api/v1/organizations/')
        .then((res) => {
          const data = res.data
          context.commit('setOrganizations', data)
        })
        .catch((err) => {
          console.log(err)
        })
    },
  }
}
