import { createStore } from "vuex";
import { ModuleUser } from "./modules/moduleUser";
import { ModuleOrganization } from "./modules/moduleOrganization";
import { ModuleOrganizations } from "./modules/moduleOrganizations";
import { ModuleSurvey } from "./modules/moduleSurvey";
import { ModuleResults } from "./modules/moduleResults";
import { ModuleSurvey360 } from "./modules/moduleSurvey360";
import { ModuleTasksGoals } from "./modules/moduleTasksGoals";

export default new createStore({
  modules: {
    user: ModuleUser,
    organization: ModuleOrganization,
    organizations: ModuleOrganizations,
    survey: ModuleSurvey,
    results: ModuleResults,
    survey360: ModuleSurvey360,
    tasksGoals: ModuleTasksGoals,
  },
  getters: {},
  mutations: {},
  actions: {},
});
