<template>
  <div>
    <template v-if="!userResult">
      <div class="p-3 py-5 items-center w-full justify-between flex-row bg-white">
        <div class="category-title font-bold">{{ catTrans(category) }}</div>
        <div class="w-3/5"></div>
        <div class="flex flex-wrap items-baseline flex-row w-full justify-between w-full">
          <div class="w-full md:w-1/2 pr-1">
            <div class="w-full flex justify-center mb-2 text-sm">{{ $t('default.survey.strongest') }}</div>
            <div class="mb-2 flex flex-row w-full justify-between items-center md:align-middle"
              v-for="(question, i) in topQuestions" :key="`cq-${i}`">
              <span
                class="flex w-2/5 text-gray-700 text-xs md:text-sm text-left md:text-right h-auto md:h-28 items-center">
                {{ translator(clang, question) }}
              </span>
              <div class="flex justify-between w-3/5">
                <div class="text-xs md:text-sm option px-4" :class="wideWidth">
                  {{ ifDecimal(question['average']) }}
                </div>
                <span
                  class="text-xs leading-none text-center text-white h-6 w-11 flex justify-center items-center bg-gray"
                  :style="{ width: `${100 - question['average']}%` }">
                </span>
                <span
                  class="text-xs md:text-xs leading-none text-center text-white h-6 w-11 flex justify-center items-center"
                  :class="[`${i % 2 ? 'bg-indigo' : 'bg-red'}`]" :style="{ width: `${question['average']}%` }">
                </span>
              </div>
            </div>
          </div>
          <div class="w-full md:w-1/2 pl-1">
            <div class="w-full flex justify-center mt-6 md:mt-0 mb-2 text-sm">{{ $t('default.survey.weakest') }}</div>
            <div class="mb-2 flex md:flex-row-reverse w-full justify-between items-center"
              v-for="(question, i) in bottomQuestions" :key="`cq-${i}`">
              <span class="flex w-2/5 text-xs md:text-sm text-gray-700 h-auto md:h-28 items-center">
                {{ translator(clang, question) }}
              </span>
              <div class="w-3/5 flex justify-between flex-row-reverse md:flex-row">
                <div class="text-xs leading-none text-center text-white h-6 flex justify-center items-center"
                  :class="[`${i % 2 ? 'bg-indigo' : 'bg-red'}`]" :style="{ width: `${question['average']}%` }">
                </div>
                <span
                  class="text-xs leading-none text-center text-white h-6 w-11 flex justify-center items-center bg-gray"
                  :style="{ width: `${100 - question['average']}%` }">
                </span>
                <div class="text-xs md:text-sm option px-4" :class="wideWidth">
                  {{ ifDecimal(question['average']) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-for="(question, i) in questions" :key="`s-${i}`">
        <ScaleQuestionResult :question="question['question']" :index="i" :key="question['id']" :clang="clang"
          :class="{ 'bg-gray-200': i % 2 }" />
      </div>
    </template>
    <template v-else>
      <div class="p-3 py-5 items-center w-full justify-between flex-row bg-white">
        <div class="category-title font-bold">{{ catTrans(category) }}</div>
        <div class="w-3/5"></div>
      </div>
      <SingleValueUserResult v-for="(question, i) in questions" :question="question['question']" :index="(+index) + i"
        :key="question['id']" :clang="clang" :class="{ 'bg-gray-200': i % 2 }" title="Single Value Question" />
    </template>
  </div>
</template>

<script>
import ScaleQuestionResult from '@/components/ScaleQuestionResult'
import SingleValueUserResult from '@/components/SingleValueUserResult'
import translator from "../mixins/translator";

export default {
  name: 'CategoryResults',
  components: {
    ScaleQuestionResult,
    SingleValueUserResult,
  },
  props: {
    category: {
      required: true,
      default: null
    },
    index: {
      required: true
    },
    questions: {
      default: []
    },
    userResult: {
      default: false
    },
    clang: {
      default: 'fi'
    },
  },
  mixins: [translator],
  computed: {
    sortedQuestions() {
      if (!this.category) return
      let questions = Object.values(this.category['questions'])
      return questions.sort((span, b) => span['average'] <= b['average'] ? 1 : -1)
    },
    displaySummary() {
      let s = this.category['display_summary']
      if (this.sortedQuestions.length / 2 < s)
        s = Math.ceil(this.sortedQuestions.length / 2)
      return s
    },
    topQuestions() {
      return this.sortedQuestions.slice(0, this.displaySummary)
    },
    bottomQuestions() {
      return this.sortedQuestions.slice(this.displaySummary, this.sortedQuestions.length).reverse()
    },
    wideWidth() {
      const avg = this.topQuestions.map(ele => ele['average'])
      for (let ele of avg) {
        if (ele % 1 === 0) {
          return 'w-20'
        }
      }
      return 'w-24'
    },
  },
  methods: {
    ifDecimal(number) {
      if (number !== null) {
        if (number % 1 === 0) {
          return number.toFixed(0);
        }
        return number.toFixed(2)
      }
    },
    catTrans(category) {
      return category.translations.length === 0 ? category['name']
        : this.translator(this.clang, category)
    }
  }
}

</script>

<style scoped>
.category-title {
  margin-top: 4rem;
  padding-bottom: 1rem;
  border-bottom: 1px dotted var(--text-grey);
}
</style>
