<template>
  <div class="w-full relative modal-container">
    <div class="absolute w-full max-w-lg py-5 px-10 relative mx-auto my-auto rounded-xl shadow-lg bg-white ">
      <div class="text-center p-5 flex justify-center flex-row items-center">
        <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M26.9996 35.9996L32.9996 41.9996L44.9996 29.9996M23.5046 14.0906C25.6565 13.9188 27.6994 13.0727 29.3426 11.6726C31.1993 10.0894 33.5595 9.21973 35.9996 9.21973C38.4396 9.21973 40.7998 10.0894 42.6566 11.6726C44.2997 13.0727 46.3426 13.9188 48.4946 14.0906C50.9268 14.2841 53.2105 15.338 54.9358 17.0633C56.6611 18.7886 57.715 21.0723 57.9086 23.5046C58.0803 25.6565 58.9264 27.6994 60.3266 29.3426C61.9097 31.1993 62.7794 33.5595 62.7794 35.9996C62.7794 38.4396 61.9097 40.7998 60.3266 42.6566C58.9264 44.2997 58.0803 46.3426 57.9086 48.4946C57.715 50.9268 56.6611 53.2105 54.9358 54.9358C53.2105 56.6611 50.9268 57.715 48.4946 57.9086C46.3426 58.0803 44.2997 58.9264 42.6566 60.3266C40.7998 61.9097 38.4396 62.7794 35.9996 62.7794C33.5595 62.7794 31.1993 61.9097 29.3426 60.3266C27.6994 58.9264 25.6565 58.0803 23.5046 57.9086C21.0723 57.715 18.7886 56.6611 17.0633 54.9358C15.338 53.2105 14.2841 50.9268 14.0906 48.4946C13.9188 46.3426 13.0727 44.2997 11.6726 42.6566C10.0894 40.7998 9.21973 38.4396 9.21973 35.9996C9.21973 33.5595 10.0894 31.1993 11.6726 29.3426C13.0727 27.6994 13.9188 25.6565 14.0906 23.5046C14.2841 21.0723 15.338 18.7886 17.0633 17.0633C18.7886 15.338 21.0723 14.2841 23.5046 14.0906Z" stroke="#00D1B5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <h1 class="text-3xl text-black-900 font-display font-bold text-center
      xl:text-bold font-heading">{{successMessage}}</h1>
      </div>
      <p>{{text}}</p>
      <div class="p-3 mt-2 text-center space-x-4 md:block" v-if="successButtonLink && successButtonText">
        <button class="bg-green text-gray-100 py-1 px-2 md:py-2 md:px-4 w-full rounded-full tracking-wide
                             font-semibold font-display focus:outline-none focus:shadow-outline hover:bg-green-dark
                             shadow-lg flex justify-around items-center" type="button" @click="$router.push({name: successButtonLink})">
          {{ successButtonText }}
        </button>
      </div>
      <div class="p-3 mt-2 text-center space-x-4 md:block" v-else>
        <button class="bg-green text-gray-100 py-1 px-2 md:py-2 md:px-4 w-full rounded-full tracking-wide
                             font-semibold font-display focus:outline-none focus:shadow-outline hover:bg-green-dark
                             shadow-lg flex justify-around items-center" type="button" @click="$emit('closeModal')">
          {{ closeModalText }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SuccessModal",
  props: {
    successMessage : {
      type : String
    },
    text:{
      type : String
    },
    alertDate:{
      type: Date
    },
    successButtonLink:{
      type : String
    },
    successButtonText:{
      type : String
    } ,
    closeModalText:{
      type : String
    }
  }
}
</script>

<style scoped>
.modal-container {
  width: 100vw;
  height: 100vh;
  background: rgba(200, 199, 199, 0.4);
  position: fixed;
  top: 0;
  left: 0;
}

.absolute {
  position: absolute;
  top: 30%;
  transform: translateY(-50%);
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}
</style>