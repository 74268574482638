<template>
  <div class="survey-summary p-3 py-5 items-center w-full justify-between flex-row bg-gray-200">

    <h3 class="md:w-2/5 mb-4">{{ $t('default.survey.summary') }}</h3>

    <div class="flex items-center flex-col w-full justify-between">
      <div class="question mb-2 flex flex-col md:flex-row w-full justify-between"
        v-for="(category, i) in category_grouping" :key="`${i}`">
        <label class="w-full md:w-2/5">
          {{ catTrans(category) }}
        </label>
        <div class="answer w-full md:w-3/5 justify-between">
          <div v-for="{ average, name, normalized_average } in getAverages(category)" :key="name" class="role-container">
            <label>
              {{ name }}
            </label>
            <div class="chart">
              <BarChart :value="average" :percentage-value="normalized_average" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <SurveyTopBottomSummary360 :questions="questions" :index="1" :display_summary="display_summary" :clang="clang" />
  </div>
</template>

<script>
import BarChart from '@/components/BarChart'
import SurveyTopBottomSummary360 from '@/components/SurveyTopBottomSummary360'
import translator from "../mixins/translator";


export default {
  props: ['category_grouping', 'questions', 'display_summary', 'clang'],
  mixins: [translator],
  components: {
    SurveyTopBottomSummary360,
    BarChart
  },
  methods: {
    getAverages(category) {
      const questionsArray = Object.values(category["questions"])
      if (questionsArray.length < 1) {
        return 0
      }
      let vals = questionsArray.reduce((col, { average }) => {
        Object.entries(average).forEach(([groupName, averageValue]) => {
          if (col[groupName] === undefined) {
            col[groupName] = { average: 0, normalized_average: 0, count: 0 }
          }
          col[groupName]["average"] += averageValue
          col[groupName]["count"] += 1
          return col
        })
        return col
      }, {})
      vals = questionsArray.reduce((col, { normalized_average }) => {
        Object.entries(normalized_average).forEach(([groupName, averageValue]) => {
          col[groupName]["normalized_average"] += averageValue
          return col
        })
        return col
      }, vals)
      const finalVals = Object.entries(vals).map(([name, group]) => {
        group["normalized_average"] /= group["count"]
        group["average"] /= group["count"]
        group["name"] = name
        return group
      })
      return finalVals
    },
    catTrans(category) {
      return category.translations.length === 0 ? category['name']
        : this.translator(this.clang, category)
    }
  }
}
</script>

<style lang="scss">
.survey-summary {
  label {
    font-size: 14px;
  }

  .question {
    margin: 10px 0;
  }

  .role-container {
    display: flex;
    margin: 0;

    label {
      font-size: 14px;
      flex-basis: 60px;
      overflow: hidden;
      margin: 0 5px;
    }

    .chart {
      flex: 1;
    }

    &:nth-child(2) {
      .chart {
        .background {
          .bar {
            background: var(--halsa-blue);
          }
        }
      }
    }
  }
}
</style>
