<template>
  <AuthContainer>
    <form class="w-full max-w-sm">
      <div>
        <h1 class="
            text-2xl text-gray-500
            font-display font-bold
            xl:text-2xl
            text-left
            mb-5
            font-heading
          ">
          {{ $t("default.password_reset.reset_heading") }}:
        </h1>
      </div>
      <div class="md:flex md:items-center mb-6">
        <div class="md:w-1/3">
          <label class="block text-gray-500 font-bold text-left mb-1 md:mb-0 pr-4" for="inline-email">
            {{ $t("default.login.email") }}
          </label>
        </div>
        <div class="md:w-2/3">
          <input class="
              bg-gray-200
              appearance-none
              border-2 border-gray-200
              rounded
              w-full
              py-1
              px-2
              md:py-2 md:px-4
              text-gray-700
              leading-tight
              focus:outline-none focus:bg-white focus:border-indigo-500
            " id="inline-email" type="email" placeholder="" v-model="email" />
        </div>
      </div>
      <div class="md:flex md:items-center mb-5 mt-2" v-if="errors">
        <div class="md:w-1/3"></div>
        <div class="md:w-2/3">
          <div class="text-xs font-display font-semibold text-red text-left">
            {{ errors }}
          </div>
        </div>
      </div>

      <div class="md:flex md:items-center">
        <div class="md:w-1/3"></div>
        <div class="md:w-2/3">
          <button class="
              bg-green
              text-gray-100
              py-1
              px-2
              md:py-2 md:px-4
              w-full
              rounded-full
              tracking-wide
              font-semibold font-display
              focus:outline-none focus:shadow-outline
              hover:bg-green-dark
              shadow-lg
              flex
              justify-around
              items-center
            " type="button" @click="resetPassword()">
            <img src="../assets/svg/tail-spin.svg" alt="" class="h-6" v-if="apiRequestPending" />
            {{ $t("default.password_reset.reset_text") }}
          </button>
        </div>
      </div>
    </form>
    <SuccessModal :success-message="$t('default.password_reset.done')"
      :text="$t('default.password_reset.success_message')" success-button-link="login"
      :success-button-text="$t('default.password_reset.success_button')" v-if="emailSent"></SuccessModal>
  </AuthContainer>
</template>

<script>
import AuthContainer from "../components/AuthContainer"
import SuccessModal from '@/components/SuccessModal'
import axios from "axios";
export default {
  name: "ResetPassword",
  data() {
    return {
      email: "",
      password: "",
      apiRequestPending: false,
      errors: null,
      emailSent: false
    };
  },
  components: {
    AuthContainer,
    SuccessModal
  },
  methods: {
    validateEmail(email) {
      return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        email
      );
    },
    resetPassword() {
      var self = this;
      if (!self.validateEmail(self.email)) {
        self.errors = self.$t("default.login.error1");
        return;
      }
      self.errors = null;
      //self.apiRequestPending = true;
      axios
        .post("/auth/email/verify/", {
          email: self.email,
        })
        .then(() => {
          this.emailSent = true
        })
        .catch((error) => {
          this.errors = error.response.data.non_field_errors[0];
        });
    },
  },
  computed: {},
};
</script>
