<template>
  <MainViewContainer>
    <div class="col-span-10 md:col-span-7">
      <SurveyInvitesTable v-if="isAdminUser"></SurveyInvitesTable>
    </div>
  </MainViewContainer>
</template>

<script>
import MainViewContainer from '@/components/MainViewContainer'
import SurveyInvitesTable from '@/components/SurveyInvitesTable.vue'
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

export default {
  name: 'SurveyDetails',
  components: {
    MainViewContainer,
    SurveyInvitesTable
  },
  setup() {
    const isAdminUser = computed(() => {
      return store.getters['user/isAdminUser']
    })

    const store = useStore()
    const router = useRouter()

    const currentRoute = router.currentRoute.value
    const orgId = currentRoute.params.org || currentRoute.params.organization
    store.dispatch('organization/fetchOrganization', orgId)

    return {
      isAdminUser,
    }
  }
}
</script>
