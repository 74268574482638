<template>
  <div class="single-value-user-result flex items-center w-full justify-between"
    :class="`${question['answers'][0]['id'] % 2 ? 'odd' : 'even'}`">
    <div class=" flex px-3 py-5 items-center w-full justify-between">
      <div class="w-2/5">
        {{ translator(clang, question) }}
      </div>
      <div class="w-2/5 scale-bar-container">
        <div class="scale-bar" :style="{ width: `${proportionalWidth}%` }">&nbsp;
        </div>
      </div>
      <div class="w-1/5 flex items-center flex-col justify-between">
        <div class="question flex flex-col w-full justify-center items-center">
          <p class="text-2xl text-black-900 font-display font-bold">{{ question['answers'][0]['value'] }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import translator from "../mixins/translator";

export default {
  name: 'SingleValueUserResult',
  props: ['question', 'index', 'clang'],
  mixins: [translator],
  computed: {
    proportionalWidth() {
      if (this.question.intervals) return (this.question.answers[0].value / this.question.intervals.slice(-1)) * 100
      return this.question.answers[0].value
    }
  },
}

</script>

<style>
.single-value-user-result {
  & .scale-bar-container {
    border: 1px solid var(--text-grey);
  }

  &.even {
    & .scale-bar {
      background: var(--tailwind-red);
      border: 3px solid var(--tailwind-red);
    }
  }

  &.odd {
    & .scale-bar {
      background: var(--tailwind-indigo);
      border: 1px solid var(--tailwind-indigo);
    }
  }
}
</style>