<template>
  <MainViewContainer :showBackIcon="true">
    <div class="col-span-12 md:col-start-2 md:col-span-10 xl:col-span-9 survey-360-results">
      <h1>
        360 Survey for {{ target }}
      </h1>
      <div class="flex md:items-center mt-6">
        <div class="md:w-1/3">
          <h2>{{ $t('default.survey.results') }}</h2>
        </div>
        <div class="md:w-1/3 w-1/12">
        </div>
      </div>
      <div class="grid grid-cols-3">
        <div class="flex">
          <Loader v-if="loading"></Loader>
        </div>
      </div>
      <div v-if="surveyResult['survey_questions']" class="grid grid-cols-12 gap-1 mt-6 mb-6">

        <SurveySummary360
          v-if="Object.keys(surveyResult['category_grouping']).length !== 0 && surveyResult['display_summary'] !== 0"
          :category_grouping="surveyResult['category_grouping']" :display_summary="surveyResult['display_summary']"
          :questions="surveyResult['survey_questions']" :clang="state.clan" title="Survey Summary"
          class="col-span-12" />

        <hr class="col-span-8 col-start-3" />

        <CategoryResults360 v-for="category in categoriesWithSummaries" :questions="questionsForCategory(category)"
          :category="category" :key="`category-${category['id']}`" :clang="state.clan" title="Category Summary"
          class="col-span-12" />

        <hr class="col-span-8 col-start-3" />

        <div class="col-span-12" v-if="Object.keys(questionsPerCategory)">
          <div v-for="category in Object.keys(questionsPerCategory)" :key="category" class="mt-8">
            <h3>{{ category }}</h3>
            <div v-for="(question, i) of questionsPerCategory[category]" :key="i">
              <template v-if="question['answers'].length">
                <div v-if="question['type'] === 'SO'" class="question-container flex w-full flex-row"
                  :class="{ 'bg-gray-200': i % 2 }">
                  <div class="flex md:w-2/6 question-text">
                    {{ translator(state.clan, question) }}
                  </div>
                  <div class="md:w-4/6">
                    <ChoiceQuestionResult v-for="(role, idx) in Object.keys(question['counts'])"
                      :key="`${role}-${i}-${idx}`" :question="question" index="" :counts="question['counts'][role]"
                      :clang="state.clan" :class="{ 'bg-gray-200': i % 2 }" :showQuestion="false" :role="role"
                      title="Choice Question">
                    </ChoiceQuestionResult>
                  </div>
                </div>
                <div v-else-if="question['type'] === 'SC'" class="question-container md:flex w-full md:flex-row"
                  :class="{ 'bg-gray-200': i % 2 }">
                  <div class="md:w-2/6 question-text">
                    {{ translator(state.clan, question) }}
                  </div>
                  <div class="mt-4 md:mt-0 md:w-4/6">
                    <ScaleQuestionResult v-for="(role, idx) in Object.keys(question['average'])"
                      :key="`${role}-${i}-${idx}`" :question="question" :showQuestion="false"
                      :answers="role === 'all' ? question['answers'] : question['answers'].filter(a => a.role === role)"
                      index="" :clang="state.clan" :average="question['average'][role]" :role="role"
                      :counts="question['counts'][role]" title="Scale Question">
                    </ScaleQuestionResult>
                  </div>
                </div>
                <template v-else-if="['FT', 'ST'].indexOf(question['type']) > -1">
                  <OpenQuestionResult v-for="(role, idx) in question['roles']" :key="`${role}-${i}-${idx}`"
                    :question="question" index="" :clang="state.clan" :class="{ 'bg-gray-200': i % 2 }"
                    :answers="question['answers'].filter(a => a.role === role)" :showQuestion="idx === 0" :role="role"
                    title="Open Question">
                  </OpenQuestionResult>
                </template>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </MainViewContainer>
</template>

<script>
import MainViewContainer from '@/components/MainViewContainer'
import ChoiceQuestionResult from '@/components/ChoiceQuestionResult'
import ScaleQuestionResult from '@/components/ScaleQuestionResult'
import OpenQuestionResult from '@/components/OpenQuestionResult'
import SurveySummary360 from '@/components/SurveySummary360'
import CategoryResults360 from '@/components/CategoryResults360'
import translator from "../mixins/translator";

import { computed, onMounted, reactive, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import Loader from "../components/Loader";

export default {
  name: 'Survey360Results',
  components: {
    MainViewContainer,
    ChoiceQuestionResult,
    ScaleQuestionResult,
    OpenQuestionResult,
    SurveySummary360,
    CategoryResults360,
    Loader
  },
  mixins: [translator],
  setup() {
    const route = useRoute()
    const surveyId = route.params.id
    const store = useStore()
    const loading = ref(true)
    const reactiveWidth = ref(screen.width)

    const state = reactive({
      role: computed(() => store.state.survey360.roles),
      clan: computed(() => store.state.survey.currentLanguage)
    })

    window.onresize = () => {
      reactiveWidth.value = window.innerWidth
    }

    return {
      state,
      surveyId,
      loading,
      reactiveWidth,
    }
  },

  mounted() {
    this.$store.dispatch('survey360/fetchRoles', this.$route.params.id)
    this.setupData()
  },

  methods: {
    async setupData() {
      await this.$store.dispatch('survey360/fetchRoles', this.$route.params.id)
      if (this.state.role && this.state.role.length === 0) return
      this.$store.dispatch('results/fetchSurvey360Answers', { surveyId: this.surveyId })
    },
    groupBy(objectArray, property) {
      return objectArray.reduce(function (acc, obj) {
        let key = obj[property]
        if (!acc[key]) {
          acc[key] = []
        }
        acc[key].push(obj)
        return acc
      }, {})
    },
    questionsForCategory(category) {
      if ("survey_questions" in this.surveyResult) {
        return this.surveyResult['survey_questions']
          .filter(x => x['answers'].length
            && x['type'] === 'SC'
            && x['category']
            && x['category']['id'] === category['id'])
      }
      return []
    }
  },

  computed: {
    roles() {
      return this.$store.getters['survey360/getRoles']
    },
    target() {
      return this.surveyResult.target
    },
    surveyResult() {
      const data = this.$store.state.results.survey360Data
      if (data[this.surveyId]) {
        if (data[this.surveyId]["all"]) {
          return data[this.surveyId]["all"]
        }
      }
      return {}
    },
    surveyName() {
      if (this.surveyResult !== undefined) {
        return this.surveyResult.name
      } else {
        return this.surveyId
      }
    },
    isMobil() {
      if (this.reactiveWidth < 768) {
        return false
      }
      return true
    },
    categoriesWithSummaries() {
      if ("category_grouping" in this.surveyResult) {
        // find the right question in survey questions to get the real max value for the bar chart
        // and store that value as the `max` in the question
        // FIXME: this kind of logic would be better suited for the backend to keep it easily testable
        const newObj = Object.values(this.surveyResult['category_grouping'])
          .filter((cat) => cat['display_summary'] !== 0)
          .map((cat) => {
            const newQuestions = Object.values(cat.questions).map((question) => {
              const questionDefinition = this.surveyResult["survey_questions"].find((sq) => sq.id == question.id)
              const maxVal = questionDefinition.steps.steps[questionDefinition.steps.steps.length - 1].value
              return { ...question, max: maxVal }
            })
            return { ...cat, questions: newQuestions }
          })
        console.dir(newObj)
        return newObj
      }
      return []
    },
    questionsPerCategory() {
      if (!this.surveyResult.survey_questions) return {}
      return this.groupBy(this.surveyResult.survey_questions.map(item => {
        return {
          ...item,
          cat: item.category ? item.category.name : 'No category'
        }
      }), 'cat')
    },
  },

  watch: {
    surveyResult: {
      handler: function (newResults) {
        if (newResults !== {}) {
          this.loading = false
        }
      }
    },
  }
}
</script>

<style lang="scss">
.survey-360-results {

  .question-container {
    padding: 20px 12px;

    .question-text {
      font-size: 14px;
    }
  }

  /** FIXME: should merge these to a base.scss at some point. Also invite.vue has the same */
  select,
  option {
    padding: 0.5rem 1rem;
    line-height: 1.25;
    border: 2px solid rgba(229, 231, 235, 1.0);
    min-width: 20rem;
  }

  .category-summary-360:nth-child(even) {
    background: var(--gray-200);
  }

  .scale-question-result:nth-child(even) {
    .bar-chart {
      .background {
        .bar {
          background: var(--halsa-blue);
        }
      }
    }
  }
}
</style>
