import axios from '@/axios-instance'

export const ModuleOrganization = {
  namespaced: true,
  state: {
    organization: null,
    groups: null,
    members: null,
    defaultGroups: null
  },
  getters: {
    getOrganizationName: (state) => {
      return state.organization ? state.organization['name'] : ''
    },
    getOrganization: (state) => {
      return state.organization ? state.organization : null
    }
  },
  mutations: {
    setOrganization(state, data) {
      console.log("set org to", data)
      state.organization = data
    },
    setOrganizationGroups(state, data) {
      state.groups = data
    },
    setOrganizationDefaultGroups(state, data) {
      state.defaultGroups = data
    },
    setMembers(state, data) {
      state.members = data
    },
    addGroup(state, newGroup) {
      state.groups.push(newGroup)
    }
  },
  actions: {
    fetchOrganization({ commit }, id) {
      return axios.get(`/api/v1/organizations/${id}/`)
        .then((res) => {
          const data = res.data
          commit('setOrganization', data)
        })
        .catch((err) => {
          console.log(err)
        })
    },

    fetchGroups(context, orgId) {
      return axios.get(`/api/v1/organizations/${orgId}/groups/`)
        .then((res) => {
          context.commit('setOrganizationGroups', res.data)
          context.commit('setOrganizationDefaultGroups', res.data) //FIXME: how are really DefaultGroups defined ?
          return res.data
        })
        .catch((err) => {
          console.log(err)
        })
    },

    fetchMembers(context, orgId) {
      return axios.get(`/api/v1/organizations/${orgId}/members/`)
        .then((res) => {
          context.commit('setMembers', res.data)
        })
        .catch((err) => {
          console.log(err)
        })
    },
  }
}
