// const colors = require('tailwindcss/colors')

module.exports = {
  purge: ["./index.html", "./src/**/*.{vue,js,ts,jsx,tsx}"],
  darkMode: false, // or 'media' or 'class'
  theme: {
    extend: {
      colors: {
        indigo: {
          light: "#4e3bea",
          DEFAULT: "#4e3bea",
          dark: "#3121b8",
        },
        green: {
          light: "#00D1B5",
          DEFAULT: "#00D1B5",
          dark: "#06baa5",
        },
        red: {
          light: "#F66353",
          DEFAULT: "#F66353",
          dark: "#e74e3c",
        },
        coolGray: {
          light: "#D1D5DB",
          DEFAULT: "#D1D5DB",
          dark: "#D1D5DB",
        },
        sadGray: {
          light: "#E5E7EB",
          DEFAULT: "#E5E7EB",
          dark: "#E5E7EB",
        },
        marksGray: {
          light: "#828282",
          DEFAULT: "#828282",
          dark: "#828282",
        },
      },
    },
    fontFamily: {
      sans: ["roboto", "avenir"],
      serif: ["roboto", "avenir"],
      mono: ["roboto", "avenir"],
      display: ["roboto", "avenir"],
      body: ["roboto", "avenir"],
      heading: ["raleway", "avenir"],
    },
  },
  variants: {
    extend: {},
  },
  plugins: [require("@tailwindcss/forms")],
};
