<template>
  <MainViewContainer :showBackIcon="true" :whereToGo="whereToGo">
    <div class="col-span-10 col-start-2 send-survey">
      <h1>{{ $t('default.send_survey.send_survey_text') }}
      </h1>
      <div class="mt-6 md:mt-12">
        <h2>
          {{ $t('default.common.survey') }}
        </h2>
        <div class="flex items-start justify-between mt-6 md:mt-12">
          <div class="md:w-1/5 text-gray-600 p-1 mt-1 md:mt-0">
            <p>{{ $t('default.send_survey.survey') }}</p>
          </div>
          <div class="md:w-3/5">
            <DropDown v-if="selectedSurvey" :isEnable="true" :options="surveys" :selected-option="selectedSurvey"
              @selectOption="selectSurvey">
            </DropDown>
            <p class="text-gray-400 text-sm mt-1 flex align-center flex items-center">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M16 8V16M12 11V16M8 14V16M6 20H18C18.5304 20 19.0391 19.7893 19.4142 19.4142C19.7893 19.0391 20 18.5304 20 18V6C20 5.46957 19.7893 4.96086 19.4142 4.58579C19.0391 4.21071 18.5304 4 18 4H6C5.46957 4 4.96086 4.21071 4.58579 4.58579C4.21071 4.96086 4 5.46957 4 6V18C4 18.5304 4.21071 19.0391 4.58579 19.4142C4.96086 19.7893 5.46957 20 6 20Z"
                  stroke="#3F3F46" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <template v-if="selectedSurvey">
                &nbsp;{{ $t('default.send_survey.survey_questions', { total_questions: selectedSurvey.total_questions, survey_name: selectedSurvey.name }) }}
              </template>
            </p>
          </div>
          <div @click="surveyView" class="flex pt-2.5 pl-3 md:w-1/5">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-green cursor-pointer" viewBox="0 0 20 20"
              fill="currentColor">
              <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
              <path fill-rule="evenodd"
                d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                clip-rule="evenodd" />
            </svg>
          </div>
        </div>
        <div class="flex items-start mt-6">
          <div class="md:w-1/5 p-1 mt-1 md:mt-0">
            <p>{{ $t('default.send_survey.additional_message') }}</p>
            <p class="text-gray-400 mt-1 flex align-center flex items-center">
              <small>
                {{ $t('default.send_survey.additional_message_subtext') }}
              </small>
            </p>
          </div>
          <div class="md:w-4/5">
            <textarea rows="5" v-model="greetingText"
              class="bg-gray-300 appearance-none border-2 border-gray-200 rounded w-full py-1 px-2 md:py-2 md:px-4 text-gray-700
                                                                                                                                                                    leading-tight focus:outline-none focus:bg-white focus:border-indigo-500"></textarea>
            <p class="text-gray-400">
              <small>
                {{ $t('default.send_survey.greeting_text_message') }}
              </small>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-span-10 md:col-start-2 md:col-span-5">
      <div class="mt-4 md:mt-12">
        <h3>
          {{ $t('default.send_survey.available') }}
        </h3>
        <p>{{ $t('default.invite.invite_multiple_members') }}</p>
        <div class="flex items-start justify-between mt-6 md:mt-12">
          <div class="md:w-2/6 p-1 mt-1 md:mt-0">
            <p>{{ $t('default.send_survey.group') }}</p>
          </div>
          <div class="md:w-4/6 w-9/12 sub-selection">
            <select class="md:w-full w-11/12" v-model="groupId">
              <option class="md:w-5/5" v-for="group in groups" :key="group.id" :value="group.id">{{ group.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="flex items-start justify-between mt-6 md:mt-12">
          <div class="md:w-2/6 p-1 mt-1 md:mt-0">
            <p>{{ $t('default.dashboard.members') }}</p>
          </div>
          <div class="md:w-4/6">
            <div class="col-span-12 md:col-span-12">
              <table class="shadow-lg bg-white table-auto w-full">
                <tr v-if="!members || members.length === 0">
                  <td class="px-2 py-4">
                    <small>
                      No members in selected group
                    </small>
                  </td>
                </tr>
                <tr :class="(i + 1) % 2 ? 'bg-gray-200' : ''" v-for="(member, i) in members" :key="member['id']">
                  <td class="px-8 py-4">
                    {{ memberTitle(member) }}
                  </td>
                  <td class="px-2 py-4 font-bold text-green hover:text-green-dark cursor-pointer"
                    v-if="selectedRecipients.includes(member)"></td>
                  <td class="px-2 py-4 font-bold text-green hover:text-green-dark cursor-pointer" v-else
                    @click="selectRecipients(member)">
                    {{ $t('default.survey.add_recipients') }}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div class="flex items-start mt-12">
          <div class="md:w-2/6 text-gray-600">
            <p>{{ $t('default.invite.emails') }}</p>
            <p class="text-gray-400 w-20 mt-1">
              <small>
                {{ $t('default.invite.emails_sub_text') }}
              </small>
            </p>
          </div>
          <div class="md:w-4/6">
            <textarea rows="6" v-model="emails"
              class="bg-gray-300 appearance-none border-2 border-gray-200 rounded w-full py-1 px-2 md:py-2 md:px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-indigo-500"></textarea>
          </div>
        </div>
        <div v-if="errors.length" class="mb-2 mt-4">
          <Alerts :alertData="errors" :isError="true"
            :alertHeading="`${$t('default.invite.following')}  ${errors.length} ${$t('default.invite.errors')}`">
          </Alerts>
        </div>
        <div v-if="success">
          <Alerts :isError="false" :alertHeading="success"></Alerts>
        </div>
      </div>
    </div>
    <div class="col-span-10 mt-4 md:mt-12 md:col-start-8 md:col-span-4">
      <h3>
        {{ $t('default.survey.selected_recipients') }}
      </h3>
      <div class="md:w-5/5">
        <div class="col-span-12 md:col-span-12">
          <table class="shadow-lg bg-white table-auto w-full">
            <tr class="bg-gray-200" v-if="!allRecipients.length">
              <td class="px-8 py-4">
                {{ $t('default.send_survey.no_members') }}
              </td>
            </tr>
            <tr :class="(i + 1) % 2 ? 'bg-gray-200' : ''" v-for="(member, i) in allRecipients" :key="i">
              <td class="px-8 py-4" v-if="typeof (member) === 'object'">
                {{ memberTitle(member) }}
              </td>
              <td class="px-8 py-4" v-else>
                <span>{{ member }}</span>
                <span class="font-bold text-green ml-1">({{ $t('default.send_survey.new_user') }})</span>
              </td>
              <td class="px-0 py-4 font-bold text-red hover:text-red-dark cursor-pointer"
                v-if="typeof (member) === 'object'" @click="onDeleteButton(member)">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash"
                  viewBox="0 0 16 16">
                  <path
                    d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                  <path fill-rule="evenodd"
                    d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                </svg>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <div class="col-span-12 md:col-span-8 md:col-start-3 mt-8">
      <div class="mt-6 text-center">
        <p v-if="selectedSurvey?.name">
          {{ $t('default.invite.send_out_surveys', { surveyName: selectedSurvey && selectedSurvey.name, number: amountOfRecipients }) }}
        </p>
      </div>
      <div class="text-center mt-8 flex flex-row justify-around">
        <button class="green big items-center" type="button" @click="sendInvites()">
          {{ $t('default.send_survey.send_surveys') }}
        </button>
      </div>
    </div>
    <SuccessModal v-if="surveySent" :success-message="successMessage" :text="text" success-button-link="dashboard"
      :success-button-text="successButtonText" />
  </MainViewContainer>
</template>

<script>
import { ref } from 'vue'
import _ from 'lodash'
import axios from '@/axios-instance'
import 'vue-next-select/dist/index.min.css'
import MainViewContainer from '@/components/MainViewContainer'
import DropDown from '@/components/DropDown'
import SuccessModal from '@/components/SuccessModal'
import i18n from '../i18n'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import Alerts from '@/components/Alerts'
import { computed, reactive, onMounted } from 'vue'

const debouncedSplitEmailsToArray = _.debounce((emails, callback) => {
  const value = emails.split('\n').filter(el => (el !== '')).filter(el => el.includes("@"))
  callback(value)
  return value
}, 1000)

export default {
  name: 'Invite',

  components: {
    MainViewContainer,
    DropDown,
    SuccessModal,
    Alerts
  },

  setup() {
    const successMessage = i18n.global.t('default.send_survey.success_message')
    const text = i18n.global.t('default.send_survey.success_text')
    const successButtonText = i18n.global.t('default.send_survey.success_button_text')
    const surveySent = ref(false)
    let selectedSurvey = ref(null)
    let greetingText = ref(null)
    let groupId = ref(null)

    const store = useStore()
    const route = useRoute()
    const org = ref(route.params.org)
    const state = reactive({
      organization: computed(() => store.state.organization.organization),
      groups: computed(() => {
        const all = store.state.organization.groups
        all.sort((a, b) => a.name.localeCompare(b.name, "fi"))
        return all
      }),
      surveys: computed(() => {
        const all = store.state.survey.all
        all.sort((a, b) => a.name.localeCompare(b.name, "fi"))
        return all
      }),
    })

    onMounted(() => {
      store.dispatch('organization/fetchOrganization', org.value)
      store.dispatch('organization/fetchMembers', org.value)
      store.dispatch('organization/fetchGroups', org.value).then(() => {
        if (state.groups.length) {
          groupId.value = state.groups[0].id
        }
      })
      store.dispatch('survey/fetchAll').then(() => {
        if (state.surveys.length) {
          selectedSurvey.value = state.surveys[0]
          greetingText.value = state.surveys[0].greeting_text
        }
      })
    })

    return {
      successMessage,
      text,
      successButtonText,
      surveySent,
      state,
      selectedSurvey,
      greetingText,
      groupId,
    }
  },

  data() {
    return {
      errors: '',
      emails: '',
      emailsArray: [],
      success: '',
      selectedSurveyId: null,
      incorrectEmails: [],
      selectedRecipients: []
    }
  },

  props: {
    whereToGo: {
      default: 'dashboard'
    }
  },

  computed: {
    organization() {
      return this.$store.state.organization.organization
    },

    groups() {
      return this.state.groups
    },

    members() {
      return this.$store.state.organization.members && this.$store.state.organization.members.filter((m) => m.organization_group === this.groupId)
        || []
    },

    orgName() {
      return this.organization ? this.organization['name'] : ''
    },

    surveys() {
      return this.state.surveys
    },

    amountOfRecipients() {
      return this.selectedRecipients.length + this.emailsArray.length
    },

    allRecipients() {
      return this.emailsArray.concat(this.selectedRecipients)
    }
  },

  watch: {
    emails(val) {
      debouncedSplitEmailsToArray(val, (emmails) => {
        this.emailsArray = emmails
      })
    }
  },

  methods: {
    selectRecipients(member) {
      if (this.selectedRecipients.indexOf(member) === -1) {
        this.selectedRecipients.push(member)
      }
    },

    onDeleteButton(member) {
      this.selectedRecipients = this.selectedRecipients.filter(item => item !== member)
    },

    surveyView() {
      this.$router.push({ name: 'surveyView', params: { org: this.$route.params.org, id: this.selectedSurvey.id } })
    },

    selectSurvey(survey) {
      this.selectedSurvey = survey
      this.greetingText = survey.greeting_text ? survey.greeting_text : ""
    },

    memberTitle(member) {
      if (member.first_name && member.last_name && member.first_name !== "" && member.last_name !== "") {
        return `${member.first_name} ${member.last_name}`
      } else if (member.email && member.email !== "") {
        return `${member.email}`
      }
      return `${member.username}`
    },

    sendSurveys() {
      this.errors = []
      this.success = ''
      let finalRecepients = []
      finalRecepients = this.selectedRecipients.map((num) => {
        if (typeof (num) === 'object') {
          return num.id
        }
      })
      if (!finalRecepients.length) {
        this.errors.push(this.$t('default.send_survey.no_member_selected'))
        return
      }
      let data = {
        'recipients': finalRecepients,
        'survey_id': this.selectedSurvey.id,
        'custom_message': this.greetingText
      }
      axios.post('/api/v1/surveys/send/', data)
        .then(() => {
          this.success = i18n.global.t('default.invite.emails_success')
          this.surveySent = true
        })
        .catch((err) => {
          this.errors = err.response.data.map((e) => {
            if (e['non_field_errors']) return e['non_field_errors'][0]
          }).filter(e => !!e).toString()
        })
    },

    sendInvites() {
      this.errors = []
      this.success = ''
      const invitationData = {
        'users': this.emailsArray
      }
      let groupName = this.groups.find((g) => g.id === this.groupId).name
      // FIXME: why are we passing name to backend instead of id?
      invitationData['selected_group'] = groupName
      if (!groupName || groupName === "") {
        alert("No group selected, can't send invitations")
        return
      }
      invitationData['organization_id'] = this.$route.params.org
      invitationData['starting_survey'] = this.selectedSurvey.id
      invitationData['greeting_text'] = this.greetingText
      if (this.emailsArray.length) {
        axios.post('/api/v1/organizations/users/invite', invitationData)
          .then((res) => {
            this.emails = ''
            this.greetingText = ''
            this.errors = []
            if (res.data.invitations_sent) {
              this.success = `${this.$t('default.invite.email_send_successful')} ${res.data.invitations_sent.length} ${this.$t('default.invite.emails').toLowerCase()}`
              this.emailsArray = []
              this.selectedRecipients = this.selectedRecipients.concat(invitationData['users'])
              this.sendSurveys()
            }
            if (res.data.invalid_emails && res.data.invalid_emails.length) {
              this.errors.push(`${this.$t("default.invite.email_sending_failed")} ${res.data.invalid_emails.join(', ')} ${this.$t("default.invite.please_check_addresses")}`)
            }
            if (res.data.already_invitations_sent && res.data.already_invitations_sent.length) {
              this.errors.push(`${this.$t("default.invite.invitation_already_sent")} ${res.data.already_invitations_sent.join(', ')}`)
            }
            if (res.data.already_members && res.data.already_members.length) {
              this.errors.push(`${this.$t("default.invite.already_members")} ${res.data.already_members.join(', ')}`)
            }
            if (res.data.invalid_emails) this.emails = res.data.invalid_emails.join('\n')
          })
      } else {
        this.sendSurveys()
      }
    },
  },
}
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer
}
</style>
